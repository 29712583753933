const PlausBlack:React.FC = () => {
    return <img className="w-full cursor-pointer" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA20lEQVR4nO3TsQ3DAAzEQO+/NDNCijQf6w74BUToeQAAAAAAAAD+UV+GILflQ7YkyJYE2ZIgWxJkS4JsSZAtCbIlQbYkyJYE2ZIgWxJkS4JsSZAtCbIlQbYkyJYE2ZIgWxJkS4JsSZAtCbIlQbYkyG8HevvmdHxzOr45Hd+cjm9Oxzen45vT8Z2XA21JkC0JsiVBtiTIlgTZkiBbEmRLgmxJkC0JsiVBtiTIlgTZkiBbEmRLgmxJkC0JsiVBtiTIlgTZkiBbEmRLgmxJkC0JsiVBAAAAAAAAgOdVPvO7vG7EXm8cAAAAAElFTkSuQmCC" alt="Plus Icon"/>
}


const PlausWhite:React.FC = () => {
    return <img className="w-full cursor-pointer" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAdklEQVR4nO3UQQrCMBBA0RzPuBDvv2hA60Iv8boRtF2I0E6oOA+yzYdJmFLSnuGMB+449QzfvIw9wzMZDmMhrrSQ4fKzo8YRzfYa6qfwRZxxl+GKISA64LDFX5hZfeG3Mlx6kW/8D6Nu78uhZ7ji+jzrN1IqgSYK2LXvCFcYxQAAAABJRU5ErkJggg==" alt="Plus"/>
}


const MenuBlack:React.FC = () => {
    return <img className="w-full cursor-pointer" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAT0lEQVR4nO3WwQkAIAwDwIzezesWKuEO8jf4aBIAHpmSZEuS1w9QJK0/MiUBAPLBRTbj0zoatySpKTIlAQDywUU249M6GrckqSkyJQEg1x3OPOvHECpbTAAAAABJRU5ErkJggg==" alt="Menu Icon"/>
}

const PlayWhite:React.FC = () => {
    return <img className="w-full cursor-pointer" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAABM0lEQVR4nO3ZvytGURzH8YsSg4niDzAKu0H+CWWyYbFYZDNajVaDhYFNPYnN8PRMyiAW4h94LJJeuuWWJL+m+8l9/QX3PZxzz/meomg0Gl9CLxZxghucYQX9Rd2hD/s+V8bMo6eoK6z53jlmijrCpZ87wHhRJ3j2O0/YxnBRB/6ui00MpAZUbrFU7mSpAZU2ZpMDKi1MJAd42xh2MJoa8HGhD6YGVO7eFnpfakClg7nkgPcLfTI5oPSCXYylBlQesYWh1ID3R/fp5IDSA0aSA0ob6QFH6QGH6QHryQH3yYv4GlO/+viaBDym/sheko8SrdTDXCf1OH2XeqGJvVJGX+pbqWOVdupg6zZ1tNity3D3KX28fpH+wLGa/sTUi73YR75S+YFYwDGucIrliGfWRuMfeAVs0fCCcEjtowAAAABJRU5ErkJggg==" alt="Play"/>
}


const PauseWhite:React.FC = () => {
    return <img className="w-full cursor-pointer" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAu0lEQVR4nO3ZQQ6CQAxG4VmOlzZ4NjyFcJRJfjPJ7Gq1EEmDvm/LpAR4K1oKgN8l6SLpJmmV1GS1cW3qZ7PnvrrJXXH9bC1Jc43xhra6Zs01xifc6pE113Da/KRlzTW0U9Zcgwdw8AWiSMhBQlEk5CChKBJykFAUCTlIKIqEHCQURUIOEooiIQcJRZGQg4T+6efuevbf69NBC45D5n5jFTQftGKad62Yxs1qf3pJy5tl3DLO1Oy5AMr5PAG3DVTHaMtI4QAAAABJRU5ErkJggg==" alt="Puase"/>
}


const SahareWhite:React.FC = () => {
    return <img className="w-full cursor-pointer" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpwYAAACsklEQVR4nO3aS6hVVRgH8JVPBBVBfKSBkAMfmZCCOFMkFMUiELSJCQbSUBBpkg4a+EBFEQLFiaaWhkHiSBAxQqFBg5JE1HxAhGKSQoo9vL9Y3HVFJK/Xc9Y+96zj/o3OYMM+37ef/2/tEGq1Wq1Wq9XaHAZgRHiZYBY+w1X8q9t9nMN6jAqdCMPxObr07nesCp0EY/GjF7M5dAIMwXca81EoHdZq3AO8FkqFQbilOTtCqbBA867jlVAifCyP8aFE2JWpAbNCibA3UwOmh5JgNk5kKv4RRoYSYAa+6sPb3os4G9od3sTXmQtv/5chTMfRdJpW4SIGt7qoJTiAa3iYElv8fQiL0zZTcLjCwqM/MbOVhU9NkbQvR6UnxlblNua1svj5uFthQeexDO/ici/bxXvIEUxqZfFT8EdFhf+MFXHy89QUaCG24RucTHOCdZjcssJ74HQFhcdpzxoMDC9BWHlSPL0/aPvCe2CPPK6nIz4olAQ/ZTrqrX1W56J7ANmsm6FUmp/WRL+GUuGHDA34JY68Q4mwXR6/pZvg4+d9Sbm9Sz7fY24oCY7JqysFpzLG2RiHG6pJcxsw7Bn7XImtaWy2Bav7bfiJN1LkrUJ8SVqe9vMq9uPvZ2wb4/WXeL0/mjAGB5+T8R+lkdelBhpxNsXcvriHpS1vQoRp2IhTuJCKjYHp05gcn1jvW5/+aFX+wduhnem+lvdVOBm6g9Gh3eEtfFtRE8pZG8Q7aRaQ+ywoJ2ViGD5Jj8Fcynq5ijARxzM14P1QIryXqQEfhhJhbqYGLAklwoi0uNKM+IidEEql+VXi9l8c7Q3mNBm5F4XS6f4ytBFfhE6AwQ1cCmf+L0oXCwOxCX89p/C48Lo7Bq7QiTAZO3HlqcJvpEulrO+BmoGhcXTWUad6rVar1Wq10J/+Az9GSgv00HAGAAAAAElFTkSuQmCC" alt="Share"/>
}


const NextDoubleWhite:React.FC = () => {
    return <img className="w-full cursor-pointer" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAcUlEQVR4nO2VMQqAMBAE84kE/f9PbALGSgufM3IkhSiJMaQQvCm32IXjbs8Y5S3ABCyAq9FbAmYi29ksp7cEWGBNZjswlnQNsTquagCXVlQIT/q3ArjfwFDS1dz+eCyCrFqmrkOvuvaZh+O7PBzFXDgADptKZxFuKToAAAAASUVORK5CYII=" alt="Next"/>
}


const CheckWhite:React.FC = () => {
    return <img className="w-full cursor-pointer" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEUElEQVR4nO3dW4hVVRzH8a2jWY2S1pQIXlMUphIlSUXzQTFExVJfNCFoJAiakgQRhLQIelAxFAUDr6AmCEbKgJMXxBctIxJj7EHL6GpoNzW8Nd9YzB4YRuec/znus/9nrfX/vLvPb5/fnLP3Xvu/j0lijDHGGGOMMcYYY4wxxhhjjPEL0AtoBD4BPgWWAr21c0UJ6A18wd1agDrtfNEBttG1Hdr5ogK8ALQWKOQ60E07ZxSAPsBFCnNlPaidNQrARoo7q50zCsDzwH+CQl7WzhrLKW6LoIwjdvzIAbBGUMbfwOA88kQNeA64Iyjkde2swQMecAdpQRnH7asqB8B7gjLcdceIPPJEDRgN3BQU8rZ21uABPYDTgjJOATXaeYMHLBeUcQN4Sjtr8ICRwL+CQlZoZw0e0B04ISjja6Cndt7gAW8KyrgNPKudNXjAEOAfQSHva2cNHtANaBaUcc6W1nMALBaU4VZ6J+WRJ2rAAOAPQSFrtbNGgbapkWK+A2q1swYPWCgow92SnaadNXhAHXBJUMgm7axRAPYKyvjBDTZoZw0eMBuZGdpZgwc8AvwoKGObdtYoUHjqsN0vQD/trMEDphaZOmw3Vztr8IBa4IKgjI+1s0YB2dThZeAJ7azBAyYKpw4XamcNHvKpw4PaWaMArBaU8RcwUDtr8ICxwC1BIQ3aWWMZ5flKUMZRL6cO07HKRcA64B2gPqliwCpBGVeBoYlv3Pcr8E2nnXEDyMuTKgQ8I5w6bEx8BHxWYKe2VtNIDFDTxROynblxn+6Jb4BBgp077BbtkioALBPkdYNwIxMfAeORcV9pQzyZOlyW+Ap4VHiV6/wKjKvyqcPPvR+QTo8TUteAFxUyviHI5g70Tye+Ax4CmkooxZ2BLanCqcOVSSjSsxfJimlHH7kLtByyHRJkOVNNZ4OZcX/5JRxTSN+sig0KAA3CAWmVY1sugHnCs5mOf50DFacOP0hCB0wQzjW1+wkYk3GG/YLX/TaaAWngyXQiXMqtHc3M6LUXCF7PfbVOTmKSXqe457Slbt/vQ/bAY8JP54dJxHfldlGa9eWuJQF7BNv/Puqf3Esfenm3xFL2uWucEl9nlmC7btRneuX21iPAq8K7dO1OAo9nPHW4ufJ76hE3vp/ep5Y6D4wSbHeLYFs/A33z2VOPuIfqBT+H19EVYEoGU4dz8t1Tj6QXbl8id8PdJr7Hdh5OP0XF7NTZU/9GOA+UUEqrOznotI0Ngn/3u/RYFL10YVLypnbkptR7ljB1OD/6N7qMT8vSEhcmm9Olj2L2WRllAl5Kf/QrK5eB/lbI/f+O4W8ZFbLIysgAMEw4BF1Ik5WRIffYGHCM8rifZR1khVRmRHVnGYW8ZmVUfmGyVVjGMS8HpH0DvCKYyXVnaMO1s0aDtjWrPwsU8pZ2xugA9V2sXW23ryrd/+dpCbA7nZycrZXFGGOMMcYYY4wxxhhjkmr0PzeHWP8QvLk8AAAAAElFTkSuQmCC" alt="Check"/>
}


const NextCircleBlack:React.FC = () => {
    return <img className="w-full cursor-pointer" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACKUlEQVR4nO1Yy2oUQRQtZ6n76XvOnUkg+Ej0K1z5Ihu3QT8gzloZcJHs4usXIriOmiD4AaI/EdEfcCuaaOLIhR4maUK6Z+rRUerAhd5U9TlV99Z9OJeRkZGRcZYgIvOqegfAAMCj0gaqehvAnDuLKIriGoAXJL+SHNXYFwDPRGSpbd4OwCKAHZJ/GhCvmq15S/JKG9w7JB8D2J+B+DEDsAdgaHsmYV4UxQWS732JnyDkne0dnTyAT6HJc2IfY4roxDh5nnATUdzJfD42eU5EDEOTv2zBllDAftDXqXwqR4ntdbAkNeM7P7ZvInKP5CrJ71OsOwyS7AA893SHtfFeqnodwI8p1j/xFtCwPDhNwE7lQG40jScAn73IW/EVKCifVg5lGcCvJmtVtTezAKsqQwUlgPWje4vIXZK/69aJyE2fGxjUBRrJVyQ3mli3212o7L9C8qDmH6s+AoY1p7o58+aTf2xGS2qJBLyMJoDkgyYudKTzOtX6/T6TupD+B0E8908/owbrYVtMZLvOF9aAt1hKbHgLEJGrvsWcqt4vH4SpijkAiy4EyunBKLFtuVBQ1UuJG5q94OOWuqQWWMBDFwEda7gTCNh2zp2LIcBu4byNPiKS/2D/cAlmQzFuYjs6+Yo7DUMENoCfpc/HcZu6cQvJN2VRNy15W7PV6/UuurZh0wNrwK2HbXDiu5ZhgyWp0CDZF5FbVgaPy2j7tqrSuzDLyMjIyHCB8RdHwf4wMaYNzAAAAABJRU5ErkJggg==" alt="Next"/>
}

const MenuWhite:React.FC = () => {
    return <img className="w-full cursor-pointer" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA2UlEQVR4nO3aOwrCQBiF0QFXo+LafVTqpkTtbK4ErLRyTPAXz1lAEuZCpvlaAwAAAAAA+GNJlkl2Sc7hU8MZbpMsesdYJbl+/Bk8G8501TPI8eVRjOXw7hizJLfRXs+z4WxnBvnVQfyyJrd/7wJxqU/p0nWpP0ZZJNkkOU36if/hlGSdZN41BgAAAAAAAAAA9FG/j0r9XpT6vSD1ezHq92LU78Wo3wtRvxehfgcAAAAAAAAA4BvU76NSvxelfi9I/V6M+r0Y9Xsx6vdC1O9FqN8BAAAAAIBGa3dzrLjHogbwCQAAAABJRU5ErkJggg==" alt="Menu"/>
}


interface IArrowBack {
    onClick?: any;
    className?: any;
    title?: any;
}

export const BackArrowBold = ({onClick}:IArrowBack) => {

   
    return <img 
    onClick={onClick}
    alt="left-arrow"
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAs0lEQVR4nNXWPQoCMRCG4fcEWsgq+AOKlXowj6mlhZ2uhWujNuolVhYiLJIUwpcPHJj6IZNkZuBPYgKcgC3QzYlcgDrkDuiokTFQtZBPrpXIKIGUQE+FDMKd1F95BoZKpIwgVThlVuQKTFVIP4HcgJkSOUaQOzBXIocI8gAWKqRIIE9gqUKa2EeQF7BSIlaocJXO9hisz9v6Ya0tyNpUrWPCOviso9y6nNDCmpJtcq5bP8UbhdWADWvaLVIAAAAASUVORK5CYII="
    />
}


export const ForwardArrowBold = ({onClick,className,title}:IArrowBack) => {

    return <img 
    onClick={onClick}
    className={`${className || ""}`}
    title={`${title || ""}`}
    alt="forward"
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAACXBIWXMAAAsTAAALEwEAmpwYAAAArUlEQVR4nNXWPQrCQBCG4fcEsQkJ6oY0ksaT5ZaxE2wVf9HKFF7CIKwgsulmPnRg6geGb2cWfqQmwArYAZUXkgFr4Bn74oW1H8i7z0CwhvI4shQ2t8aKEezkgZXAfgSbWWMhhuEbO3pgr8RdVVgN3BLYAZhaYwvgrsIaoFdhS+CRwDZ/CTWK0UnCUCviXSkebFCsoFKxVAvFmchVh69VnfJM9Tkhfrc6YOuJmNYA6EaADfD8J8gAAAAASUVORK5CYII="
    />
}


export const TreeIcon = ({onClick,className,title}:IArrowBack) => {

    return <img 
    onClick={onClick}
    className={`${className || ""}`}
    title={`${title || ""}`}
    alt="forward"
    src="https://user-images.githubusercontent.com/22852149/114907167-16472900-9e1b-11eb-9a01-cda6351d2d9c.png"
    />
}


const Icons = {
    TreeIcon,
    PlausBlack,
    PlausWhite,
    MenuBlack,
    PlayWhite,
    PauseWhite,
    SahareWhite,
    NextDoubleWhite,
    CheckWhite,
    NextCircleBlack,
    MenuWhite
}


export {Icons};

