import React, { ReactNode, KeyboardEvent } from 'react';

interface ModalProps {
  children: ReactNode;
  title?: string;
  onEscape?: () => void;
  cancelBtnText?: string;
  saveBtnText?: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onSubmit: (v:any,e:any) => void;
  hideBtns?: boolean;
  showTopArrow?: boolean;
}

export const Modal: React.FC<ModalProps> = ({children,title,onEscape,cancelBtnText = "Cancel",saveBtnText = "Save",isOpen,setIsOpen,onSubmit,hideBtns = false,showTopArrow = true}) => {

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.code === "Escape" && onEscape) {
      onEscape();
    }
  }

  if (!isOpen) {
    return null;
  }

  return (
    <div tabIndex={0} onKeyDown={handleKeyDown} className='fixed z-[101] top-0 left-0 w-full h-full bg-black/60'>
      <div className='w-full h-full overflow-auto p-2 md:p-0'>
        <div className='bg-white my-4 mx-auto w-[600px] max-w-full h-auto rounded-md'>
          <div className="flex items-start justify-between p-2 border-b rounded-t dark:border-gray-600">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
              {title}
            </h3>
            {
              showTopArrow && <button onClick={() => setIsOpen(false)} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="defaultModal">
                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                <span className="sr-only">Close modal</span>
              </button>
            }
          </div>
          <div className="p-6 space-y-6">
            {children}
          </div>
          {
            !hideBtns && <div className="flex items-center justify-end p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
              <button onClick={() => setIsOpen(false)} data-modal-hide="defaultModal" type="button" className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">{cancelBtnText}</button>
              <button onClick={(e:any)=> onSubmit(e,"")} data-modal-hide="defaultModal" type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">{saveBtnText}</button>
            </div>
          }
        </div>
      </div>
    </div>
  )
};

