const topTitles = ["Life", "Life Divisions", "Categories of Improvement", "Projects & Objectives"];

export const getLabelFromDepth = (depth:number) => {

    if(typeof(depth) !== "number"){
        return "";
    }

    if(depth <= 3){
        return topTitles[depth]
    }

    return `OKR L${(depth - 3)}`
}