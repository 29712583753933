import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { createEventForXLRate, createTag, deleteTag, getAllTagsOfUser, getSurveyTitle, setIsCreateCognipulseModalOpen, setIsPhySelfyModalOpen } from "../../store/actions";
import { toast } from "react-toastify";
import { Modal } from "./Index";
import { Button } from "../Button";

  interface PhySelfyProps {
    newCognipulseId?: string | null;
  }


  
  const EMOJIES = [
    {emoji: "😀", name: "Happy"},
    {emoji: "😔", name: "Pensive"},
    {emoji: "😳", name: "Embarrassed"},
    {emoji: "😫", name: "Overwhelmed"},
    {emoji: "😟", name: "Unhappy"},
    {emoji: "😎", name: "In the Zone"},
    {emoji: "😊", name: "Positive"},
    {emoji: "🥰", name: "Loved"},
    {emoji: "😧", name: "Worried"},
    {emoji: "😠", name: "Angry"},
    {emoji: "🤬", name: "Very angry"},
    {emoji: "​​😕", name: "Uncertain"},
    {emoji: "​😲​", name: "Surprised"},
    {emoji: "😔​​", name: "Sad"},
    {emoji: "🥱", name: "Tired"},
    {emoji: "😎", name: "Cool"},
    {emoji: "😐", name: "Ok-ish"},
    {emoji: "🥳", name: "Excited"},
    {emoji: "💪", name: "Motivated"},
    {emoji: "😫", name: "Stressed"},
    {emoji: "🥺", name: "Lonely"},
    {emoji: "🙏", name: "Grateful"},
    {emoji: "😲", name: "Surprised"},
    {emoji: "😤", name: "Frustrated"},
    {emoji: "😰", name: "Anxious"},
    {emoji: "🧐", name: "Deep Thought"},
    {emoji: "🌊", name: "In Flow"}
  ]

  
  export function CreatePhySelfie({newCognipulseId}:PhySelfyProps){
    const dispatch:AppDispatch = useDispatch();
    const [selectedEmojies, setSelectedEmojies] = useState([]); 
    const loggedInUser = useSelector((state:RootState) => state.loggedInUser);
    const [eventData, setEventData] = useState<any>({
      date: "",
      tags: [],
      description: "",
      surveytitle: "",
      surveyName: ""
    });
    const [tags, setTags] = useState(
      [
        {tag: "Relationships",selected: false},
        {tag: "Professional",selected: false},
        {tag: "Teams Meeting",selected: false},
        {tag: "Personal",selected: false},
        {tag: "WFH",selected: false},
      ]
    );
    const cognipulseGoals = useSelector((state:RootState) => state.globalStates.cognipulseGoals);
    const [newTag, setNewTag] = useState('');
    const [surveyTitle, setSurveyTitle] = useState<any>(null);
  
  
    const handleEmojiSelect = (emojiName:any) => {
      setSelectedEmojies((prev:any) => {
        const emoji =  prev.find((emoji:any) => emoji === emojiName);
        if(emoji){
          return prev.filter((em:any) => em !== emoji);
        }
        return [...prev,emojiName];
      })
    }
  
    const handdleTagSelect = (tag:any)=>{
      setTags(prev => {
          return prev.map(t => t.tag === tag? {...t,selected: !t.selected}:t);
      })
    }
  
    const handdleEventInputChange = (e:any)=> {
        if(e.target.id === "description"){
          // const len = e.target.value.split("\n").length;
          // console.log(len);
          // const height = 29*len;
          // e.target.style.height = `${height < 64? 64:height}px`; 
          e.target.style.height = 'auto';
          e.target.style.height = `${e.target.scrollHeight}px`;
          const textarea = e.target;
          textarea.scrollTop = textarea.scrollHeight;
        }
        // console.log(e.target)
        setEventData((prev:any)=>{
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        })
    }
  
    const handleCapture = async (e:React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      
      const t = tags.filter(tag => tag.selected);
      const selectedTags = t.map(t => t.tag);
      const data = {
        ...eventData,
        surveytitle: surveyTitle._id,
        tags: selectedTags,
        userIdIn10X: loggedInUser._id,
        isManagementEvent: false,
        activity: cognipulseGoals.categoryOfImprovementGoal?.activity,
        feelings: selectedEmojies,
        date: eventData.date || moment().toDate(),
        newCognipulseId: newCognipulseId,
        survey: newCognipulseId,
      }
      
    
      const res = await createEventForXLRate(data);
  
  
      if(res.success){
        dispatch(setIsCreateCognipulseModalOpen(false));
        dispatch(setIsPhySelfyModalOpen(false));
      }
      else {
        console.log(res.message);
        
      }
    }
  
    const handleDeleteTag = async (tagId:any) => {
      const isSure = window.confirm("Are You Sure!");
  
      if(!isSure){
        return;
      }
  
      const res = await deleteTag(tagId);
      if(res.success){
        toast.success("Tag Deleted Successfully!");
        setTags(prev => {
          return prev.filter((tag:any) => tag._id !== tagId);
        })
      }
      else {
        toast.error(res.message);
      }
    }
  
    const handdleAddTag = async (e:any)=> {
      e.preventDefault();
      const res = await createTag({tag: newTag,userIdIn10X: loggedInUser._id});
      if(res.success){
        setTags(prev => {
          return [...prev,{tag: newTag,selected: false,_id: res.tag._id}]
        });
        setNewTag("");
      }
      else {
        toast.error("someting went wrong");
      }
    }
    
    useEffect(() => {
      (async () => {
        const res = await getAllTagsOfUser();
        
        if(res.success){
          const tags = res.tags.map((tag:any) => {return {...tag,selected: false}});
          setTags(prev => {
            const uniqueTags:any = [];
            const allTags = [...prev,...tags].filter(item => {
                if(uniqueTags.indexOf(item.tag) === -1){
                    uniqueTags.push(item.tag);
                    return item;
                }
  
                return undefined;
            });
            return allTags;
          });
        }
        else {
          toast.error("Failed to load your tags.");
        }
      })()
    }, [loggedInUser.email]);


    useEffect(() => {
        (async () => {
            const res = await getSurveyTitle(false);
            if(res.success){
                setSurveyTitle(res.surveyTitle)
            }
        })()
    }, [loggedInUser.email]);


    if(!surveyTitle){
        return <Modal 
          saveBtnText="Create"
          onSubmit={()=>{}}
          isOpen={true}
          setIsOpen={(bool)=>{dispatch(setIsPhySelfyModalOpen(bool))}}
          hideBtns={true}
          onEscape={()=>{dispatch(setIsPhySelfyModalOpen(false))}}
        >
          <div className="h-[632px] flex justify-center items-center">Loading...</div>
        </Modal>
    }
  
    return <Modal
        saveBtnText="Create"
        onSubmit={()=>{}}
        isOpen={true}
        setIsOpen={(bool)=>{dispatch(setIsPhySelfyModalOpen(bool))}}
        hideBtns={true}
        onEscape={()=>{dispatch(setIsPhySelfyModalOpen(false))}}
    >
      <>
      <form className=''>
                    <h6 className='font-bold text-gray-500 flex gap-2 items-center text-secondry-color mb-2'>How are you feeling right now?</h6>
                    
                    <div className="flex mb-6 gap-2 overflow-x-auto border border-secondry-color p-2 rounded-lg">
                        {
                          EMOJIES.map((item:any,idx) => (
                            <>
                              {
                                selectedEmojies.indexOf(item.name as never) !== -1 &&  <div onClick={()=>handleEmojiSelect(item.name)} className="transition-all p-2 bg-green-400 rounded-md flex flex-col cursor-pointer select-none justify-center items-center pb-2" key={idx}>
                                                                                <span className='text-[30px] transition-all'>{item.emoji}</span>
                                                                                <span className={`text-white text-xs text-center text-nowrap transition-all`}>{item.name}</span>
                                                                              </div>
                              }
                              {
                                selectedEmojies.indexOf(item.name as never) === -1 &&  <div onClick={()=>handleEmojiSelect(item.name)}  className="flex flex-col border px-1 rounded cursor-pointer select-none justify-center items-center pb-2" key={idx}>
                                                                                <span className='text-[40px]'>{item.emoji}</span>
                                                                                <span className={`"text-gray-400 text-sm text-center text-nowrap`}>{item.name}</span>
                                                                              </div>
                              }
                            </>
                          ))
                        }
                    </div>
  
                    <p className='mb-1 text-[16px] text-gray-500 font-bold items-center mb-4'><span className='mr-2'>Select or add your own tag to quickly capture what’s on your mind right now</span></p>
                    
                    <div className="tags-box flex items-center flex-wrap mb-3 gap-2">
                        {
                            tags.map((tag:any,i) => {
                                return <div className={`border border-secondry-color/20 cursor-pointer rounded-md flex  select-none ${tag.selected? "bg-gray-500 text-white":"text-gray-600"}`} key={i}>
                                <p onClick={(()=>handdleTagSelect(tag.tag))} className="border-r px-2 py-1 font-bold rounded-md text capitalize text-xs mb-0">{tag.tag}</p>
                                {
                                  tag._id && <div onClick={()=>handleDeleteTag(tag._id)} className='flex items-center self-stretch bg-secondry-color/20 rounded-tr-md rounded-br-md'>
                                                X
                                             </div>
                                }
                              </div>
                            })
                        }
                    </div>
  
                    <div className="tags-input mb-6 flex gap-2 items-center">
                      <div className="form-group flex-1">
                        <input value={newTag} onChange={(e)=>setNewTag(e.target.value)}  type="text" className="bg-gray-100 w-full rounded-md px-2 py-2" id="tag-input" placeholder="Type your own tag.."/>
                      </div>
                      <div>
                        <Button onClick={handdleAddTag} label="+ Add"/>
                      </div>
                    </div>
  
                    <div className="mb-3">
                      <label className='mb-1 text-gray-500 font-bold flex gap-2 items-center'>Add some detail?</label>
                      <textarea onChange={handdleEventInputChange} name="description" id='description' className="bg-gray-100 p-2 rounded-md w-full" placeholder="Enter details to truly capture the what you are experiencing."/>
                    </div>
                    <div className="mb-3">
                      <label className='mb-1 text-gray-500 font-bold flex gap-2 items-center'>When did this happened</label>
                      <input onChange={handdleEventInputChange} name="date" type="datetime-local" className="bg-gray-100 w-full p-2 text-gray-500 rounded-md" id="date"/>
                    </div>
  
                    <div className="w-full">
                      <Button onClick={handleCapture} className="w-full mt-12" label="Capture"/>
                    </div>
                </form>
      </>
    </Modal>
  }