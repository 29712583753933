import { useEffect, useRef } from "react";


export function XLRate() {
    const iframeRef = useRef<HTMLIFrameElement | null>(null);


    useEffect(() => {
        if(iframeRef && iframeRef.current){
            const iframe = iframeRef.current;

            iframe.onload = () => {
                iframe.contentWindow?.postMessage(
                  {
                    authToken: 'your-auth-token',
                    userId: '12345',
                  },
                  'https://iframe-website.com' // Target origin of iframe
                );
            };
        }
    }, []);


    return (
        <div className="w-full h-full max-h-[calc(100vh-64px)] overflow-auto p-2">
            <iframe ref={iframeRef} referrerPolicy="same-origin" src="https://xlrate.10x.systems" height="100%" width="100%" title="Iframe Example"></iframe>
            {/* <iframe ref={iframeRef} referrerPolicy="same-origin" src="http://localhost:3001?embedded=true" height="100%" width="100%" title="Iframe Example"></iframe> */}
        </div>
    )
}
