import { useRef, useState } from "react";
import {Modal} from "../Modal/Index";
import { useSelector, useDispatch } from "react-redux";
import {
   setAddNodeModalOpen,
   createNode, 
   pauseTask,
   setObjectiveArr,
   setDeadline,
   createPlanningDuration
} from "../../store/actions";

import {Formik} from "formik";
import { toast } from "react-toastify";
import {RootState} from "../../store/store";
import moment from "moment";
import { NODE_TYPES } from "../../constants";
import { capitalized } from "../../utils";
import { InputField, TextArea } from "../FormFields";
import RecurringForm from '../RecurringForm';
import { MissionModal } from "./MissionModal";
import { MoonShotModal } from "./MoonShotModal";
import { ObjectiveModal } from "./ObjectiveModal";
import { KeyResultModal } from "./KeyResultModal";
import { TaskModal } from "./TaskModal";
import { arrayToObject, formatPurpose, Purpose } from "./Purpose";


export function AddNodeModal() {
  const [nodeObj, setNodeObj] = useState<any>({
      role: "",
      objective: "",
      objectiveArr: [],
      result: "",
      body: "",
      deadline: "",
      legtime: "",
      leadtime: "",
      taskOrder: 0,
      estimatedTaskDuration: 1,
      color: ""
  });
  const dispatch:any = useDispatch();
  const isAddNodeModalOpen = useSelector((state: RootState) => state.globalStates.isAddNodeModalOpen);
  const parentId = useSelector((state: RootState) => state.globalStates.parentId);
  const createNodeBtnClickTime = useSelector((state: RootState) => state.globalStates.createNodeBtnClickTime);
  const objectiveArr = useSelector((state: RootState) => state.globalStates.objectiveArr);
  const deadline = useSelector((state: RootState) => state.globalStates.deadline);
  const currentTask = useSelector((state: RootState) => state.globalStates.currentTask);
  const tree = useSelector((state: RootState) => state.tree);
  const [timingData,setTimingData] = useState({
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    time: "",
    days: [],
    frequency: ""
  });
  const [startTime] = useState(new Date());
  const [isShowMissionModal, setIsShowMissionModal] = useState(false);
  const [isShowMoonshotModal, setIsShowMoonshotModal] = useState(false);
  const [isShowObjectiveModal, setIsShowObjectiveModal] = useState(false);
  const [isShowKeyResultModal, setIsShowKeyResultModal] = useState(false);
  const [isShowTaskModal, setIsShowTaskModal] = useState(false);
  
  if(deadline){
    nodeObj.deadline = deadline;
  }
  if(objectiveArr){
    nodeObj.objectiveArr = objectiveArr;
    nodeObj.objective = objectiveArr.join(" ");
  }

  const handleSubmit = async (values:any,e:any) => { 
    
    if(values.isRecurringTask && timingData.days.length === 0){
      toast.error("Recurring Tasks Should have at least one day selected");
      return;
    }

    if(!values.estimatedTaskDuration || values.estimatedTaskDuration === 0){
      toast.error("Estimated Task Duration can't be zero or Undefined");
      return;
    }

    let isProjectAndObjectiveNode = false;

    tree?.children.forEach(child => {
      child.children.forEach(chidl1 => {
        if(chidl1._id === parentId){
          isProjectAndObjectiveNode = true;
        }
      })
    });


    if(isProjectAndObjectiveNode && (!values.deadline || new Date(values.deadline) < new Date())){
      if(parentId === "668261e1c1c046a9f34c8472" || parentId === "668284ed97ec14f3d5628a0b"){
        values.deadline = moment().endOf('day').format("YYYY-MM-DDTHH:mm:ss");
      }
      else {
        toast.error("Deadline is required and must be set in the future.")
        return;
      }
    }

    
    function createNodeLocal() {

      // remove empity values 
      for (const key in values) {
        if (!values[key]) {
          delete values[key]
        }
      }


      dispatch(createNode({...values,parent: parentId,startTimeInMs:createNodeBtnClickTime,endTimeInMs: new Date().getTime(),isRecurringTask: timingData.frequency? true:false,recurring: timingData.frequency? {...timingData}:undefined}));
      e.resetForm();
      setTimingData({
        startDate: "",
        endDate: "",
        startTime: "",
        endTime: "",
        time: "",
        days: [],
        frequency: ""
      });
      dispatch(setAddNodeModalOpen(false));
      dispatch(setDeadline(null));
      dispatch(setObjectiveArr(null));
    }

    // if you are adding the node the the running node 
    if(currentTask && currentTask?._id === parentId && currentTask?.startTime){
      const funcs = {
          preRun: () => {},
          runIfSuccess: createNodeLocal,
          runIfFails: () => {}
      }

			dispatch(pauseTask(currentTask._id,"",false,funcs));
      console.log("Task has been paused here");
    }
    else {
      createNodeLocal();
    }
  };

  const onEscape = async () => {
    dispatch(setObjectiveArr(null));
    dispatch(setDeadline(null));
    dispatch(setAddNodeModalOpen(false));
    if(parentId){
      await createPlanningDuration({startTime,endTime: new Date(),node: parentId});
    }
  }

 
  if(isShowTaskModal && isAddNodeModalOpen){
    return <TaskModal setIsOpen={setIsShowTaskModal} setNodeObj={setNodeObj} objectiveDynamicProperties={nodeObj.objectiveDynamicProperties}/>
  }

  if(isShowKeyResultModal && isAddNodeModalOpen){
    return <KeyResultModal setIsOpen={setIsShowKeyResultModal} setNodeObj={setNodeObj} objectiveDynamicProperties={nodeObj.objectiveDynamicProperties}/>
  }

  if(isShowObjectiveModal && isAddNodeModalOpen){
    return <ObjectiveModal setIsOpen={setIsShowObjectiveModal} setNodeObj={setNodeObj} objectiveDynamicProperties={nodeObj.objectiveDynamicProperties}/>
  }

  if(isShowMoonshotModal && isAddNodeModalOpen){
    return <MoonShotModal setIsOpen={setIsShowMoonshotModal} setNodeObj={setNodeObj} objectiveDynamicProperties={nodeObj.objectiveDynamicProperties}/>
  }

  if(isShowMissionModal && isAddNodeModalOpen){
    return <MissionModal setIsOpen={setIsShowMissionModal} setNodeObj={setNodeObj} objectiveDynamicProperties={nodeObj.objectiveDynamicProperties}/>
  }

  return (
    <Modal
      saveBtnText="Create"
      onSubmit={()=>{}}
      isOpen={isAddNodeModalOpen}
      setIsOpen={(bool)=>dispatch(setAddNodeModalOpen(bool))}
      hideBtns={true}
      onEscape={onEscape}
    >
      <Formik
      initialValues={{...nodeObj,isRecurringTask: false,type: nodeObj.type || NODE_TYPES[2]}}
      validate={()=>{}}
      onSubmit={handleSubmit}
      >
        {(e:any)=>(
          <Form e={e} setIsShowTaskModal={setIsShowTaskModal} setIsShowKeyResultModal={setIsShowKeyResultModal} setIsShowObjectiveModal={setIsShowObjectiveModal} startTime={startTime} timingData={timingData} setTimingData={setTimingData} setIsShowMissionModal={setIsShowMissionModal} setNodeObj={setNodeObj} setIsShowMoonshotModal={setIsShowMoonshotModal}/>
        )}
      </Formik>
    </Modal>
  );
}


interface FormProps {
  e: {
    values: {
      title?: string;
      role?: string;
      objective: string;
      result: string;
      purpose: string[];
      body: string;
      deadline: string;
      legtime: string;
      leadtime: string;
      taskOrder: number;
      estimatedTaskDuration: number;
      color: string;
      isImmediateTask: boolean;
      isRecurringTask: boolean;
      type: string;
      objectiveDynamicProperties: any;
    };
    handleChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    handleSubmit: (event: any) => void;
    setFieldValue: any;
  };
  timingData: any;
  setTimingData: React.Dispatch<React.SetStateAction<any>>;
  startTime: Date;
  setIsShowMissionModal: any;
  setNodeObj: any;
  setIsShowMoonshotModal: any;
  setIsShowObjectiveModal: any;
  setIsShowKeyResultModal: any;
  setIsShowTaskModal: any;
}

const Form: React.FC<FormProps> = ({ setIsShowTaskModal,setIsShowKeyResultModal,setIsShowObjectiveModal,e, timingData, setTimingData,startTime ,setIsShowMissionModal,setNodeObj,setIsShowMoonshotModal}) => {
  const dispatch:any = useDispatch();
  const submitBtnRef = useRef<HTMLButtonElement>(null);
  const depth = useSelector((state: RootState) => state.globalStates.depth);
  const parentId = useSelector((state: RootState) => state.globalStates.parentId);
  const [isShowPurpose, setIsShowPurpose] = useState(false);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.code === "Enter") {
      submitBtnRef.current?.click();
    }
  };

  const handleNodeTypeChange = (ev:any) => {
    e.handleChange(ev);
    setNodeObj((prev:any) => {
      return {
        ...prev,
        type: ev.target.value
      }
    });
  }

  const handleTitleChange = (ev:any) => {
    e.handleChange(ev);
    setNodeObj((prev:any) => {
      return {
        ...prev,
        objectiveDynamicProperties: {
          ...prev.objectiveDynamicProperties,
          title: ev.target.value
        }
      }
    })
  }

  const handleSetGoal = () => {
    if(e.values.type === NODE_TYPES[0]){
      setIsShowMissionModal(true);
    }
    if(e.values.type === NODE_TYPES[1]){
      setIsShowMoonshotModal(true);
    }
    if(e.values.type === NODE_TYPES[2]){
      setIsShowObjectiveModal(true);
    }
    if(e.values.type === NODE_TYPES[3]){
      setIsShowKeyResultModal(true);
    }
    if(e.values.type === NODE_TYPES[4]){
      setIsShowTaskModal(true);
    }
  }
  
  if(isShowPurpose){
    return <Purpose setFieldValue={e.setFieldValue} purposeArr={e.values.purpose} objective={e.values.objective} setIsShowPurpose={setIsShowPurpose}/>
  }

  return (
    <>
      <button
        onClick={handleSetGoal}
        className='text-sm bg-blue-600 text-white px-3 py-1 shadow-sm rounded-md'
      >
        Set {e.values.type.split("_").map(n => capitalized(n)).join(" ")}
      </button>
      <form className="w-full" tabIndex={0} onKeyDown={handleKeyDown}>
        {depth === 2 && (
          <InputField
            name="role"
            value={e.values.role ?? ''}
            handleChange={e.handleChange}
            label="Role"
            type="text"
          />
        )}

        <InputField
          name="objectiveDynamicProperties.title"
          value={e.values?.objectiveDynamicProperties?.title || ""}
          handleChange={handleTitleChange}
          label={`${e.values.type.split("_").map(n => capitalized(n)).join(" ")} Title`}
          type="text"
        />
        
        <div className="md:flex md:items-center  mb-6">
                <div className="md:w-1/3">
                  <label
                    className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4"
                    htmlFor={"type"}
                  >
                    Node Type
                  </label>
                </div>
                <div className="md:w-2/3">
                    <select className='border w-full p-2 rounded-md' value={e.values.type} name='type' id='type' onChange={handleNodeTypeChange}>
                          {
                            NODE_TYPES.map((nt) => <option key={nt} value={nt}>{nt.split("_").map(n => capitalized(n)).join(" ")}</option>)
                          }
                    </select>
                </div>
        </div>

        <TextArea
          name="objective"
          isHeigLighted={true}
          value={e.values.objective}
          handleChange={e.handleChange}
          label={e.values.type.split("_").map(n => capitalized(n)).join(" ")}
        />

        <TextArea
          name="purpose"
          isHeigLighted={true}
          value={e.values.purpose? formatPurpose(arrayToObject(e.values.purpose || [])):"click here to set purpose"}
          handleChange={()=>{}}
          label={"Purpose"}
          onTextAreaClick={() => {setIsShowPurpose(true)}}
        />

        <InputField
          name="result"
          value={e.values.result}
          handleChange={e.handleChange}
          label="Key Results"
          type="text"
        />
        <InputField
          name="body"
          value={e.values.body}
          handleChange={e.handleChange}
          label="Notes"
          type="text"
        />
        <InputField
          name="deadline"
          value={e.values.deadline}
          handleChange={e.handleChange}
          label="Deadline"
          type="datetime-local"
        />
        <InputField
          name="legtime"
          value={e.values.legtime}
          handleChange={e.handleChange}
          label="Lag Time"
          type="text"
        />
        <InputField
          name="leadtime"
          value={e.values.leadtime}
          handleChange={e.handleChange}
          label="Lead Time"
          type="text"
        />
        <InputField
          name="taskOrder"
          value={e.values.taskOrder}
          handleChange={e.handleChange}
          label="Task Order"
          type="number"
        />
        <InputField
          name="estimatedTaskDuration"
          value={e.values.estimatedTaskDuration}
          handleChange={e.handleChange}
          label="Est. Duration"
          type="number"
        />
        <InputField
          name="color"
          value={e.values.color}
          handleChange={e.handleChange}
          label="Color"
          type="color"
        />
        <h6 className='py-2 flex items-center gap-2'>
          ImmediateTask
          <input
            type="checkbox"
            checked={e.values.isImmediateTask}
            name='isImmediateTask'
            id='isImmediateTask'
            onChange={e.handleChange}
          />
        </h6>
        <h6 className='p-2 flex items-center justify-center gap-2'>
          Recuring Details
          <input
            type="checkbox"
            checked={e.values.isRecurringTask}
            name='isRecurringTask'
            id='isRecurringTask'
            onChange={e.handleChange}
          />
        </h6>
        {e.values.isRecurringTask && (
          <RecurringForm timingData={timingData} setTimingData={setTimingData} />
        )}
        <div className="flex items-center justify-end p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
          <button
            onClick={async () => {
              if(parentId){
                await createPlanningDuration({startTime,endTime: new Date(),node: parentId});
              }
              dispatch(setAddNodeModalOpen(false))
            }}
            data-modal-hide="defaultModal"
            type="button"
            className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
          >
            Cancel
          </button>
          <button
            ref={submitBtnRef}
            onClick={e.handleSubmit}
            data-modal-hide="defaultModal"
            type="button"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Submit
          </button>
        </div>
      </form>
    </>
  );
};






