import React from "react";
import {Modal} from "../Modal/Index";
import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";
import {
  pauseTask,
} from "../../store/actions";
import {
  setUpcomingTaskModalOpen,
  setCurrentTask,
  setCurrentTasksParents,
  setUpcomingTask,
  setUpcomingTasksParents,
  setStartTime,
} from "../../store/actions/globalStates";

interface RootState {
  globalStates: {
    isUpcomingTaskModalOpen: boolean;
    startTime: number | null;
    currentTask: any; // Replace `any` with your actual task type
    upcomingTask: any; // Replace `any` with your actual task type
    upcomingTasksParents: any[]; // Replace `any` with your actual task parent type
  };
}

export const UpCommingTaskModal: React.FC = () => {
  const dispatch:any = useDispatch();
  
  const isUpcomingTaskModalOpen = useSelector((state: RootState) => state.globalStates.isUpcomingTaskModalOpen);
  const currentTask = useSelector((state: RootState) => state.globalStates.currentTask);
  const upcomingTask = useSelector((state: RootState) => state.globalStates.upcomingTask);
  const upcomingTasksParent = useSelector((state: RootState) => state.globalStates.upcomingTasksParents);

  const handleSubmit = (values: { isWorking: string; okrGradePercentage: number; extraMinutes: number; }, e: any) => {
    if (currentTask?.startTime) {
      const funcs = {
          preRun: () => {},
          runIfSuccess: () => {},
          runIfFails: () => {}
      }
      dispatch(pauseTask(currentTask._id, "", false,funcs));
      dispatch(setStartTime(null));
      console.log("Task has been paused here");
    }
    
    dispatch(setCurrentTask(upcomingTask));
    dispatch(setCurrentTasksParents(upcomingTasksParent));
    dispatch(setUpcomingTask(null));
    dispatch(setUpcomingTasksParents(null));
    dispatch(setUpcomingTaskModalOpen(false));
    e.resetForm();
  };

  const onEscape = () => {
    // Add any specific functionality you want on escape
  };

  return (
    <Modal
      saveBtnText=""
      onSubmit={handleSubmit}
      isOpen={isUpcomingTaskModalOpen}
      setIsOpen={(bool) => { dispatch(setUpcomingTaskModalOpen(bool)); }}
      hideBtns={true}
      onEscape={onEscape}
      showTopArrow={false}
    >
      <Formik
        initialValues={{ isWorking: "Yes", okrGradePercentage: 0, extraMinutes: 0 }}
        onSubmit={handleSubmit}
        validate={() => { }}
      >
        {(e) => <Form e={e} />}
      </Formik>
    </Modal>
  );
};





interface FormProps {
  e: any
}

const Form: React.FC<FormProps> = ({ e }) => {
  return (
    <form className="w-full">
      <FormData e={e} />
    </form>
  );
};


interface FormDataProps {
  e: {
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleSubmit: any
  };
}

const FormData: React.FC<FormDataProps> = ({ e }) => {
  const { handleChange } = e;
  const dispatch:any = useDispatch();
  
  const upcomingTask = useSelector((state: RootState) => state.globalStates.upcomingTask); // Replace `any` with your state type
  const upcomingTasksParent = useSelector((state: RootState) => state.globalStates.upcomingTasksParents); // Replace `any` with your state type
  const currentTask = useSelector((state: RootState) => state.globalStates.currentTask); // Replace `any` with your state type
  const currentTasksParents = useSelector((state: any) => state.globalStates.currentTasksParents); // Replace `any` with your state type

  const progress = [
    {
      lavel: "No real progress",
      percentage: 33,
      bg: "bg-red-500"
    },
    {
      lavel: "Made Progress but didn't complete",
      percentage: 33,
      bg: "bg-yellow-500"
    },
    {
      lavel: "Completed",
      percentage: 40,
      bg: "bg-green-500"
    },
  ];

  return (
    <>
      <div className="mb-14">
        <p>Rate your progress on {currentTask?.objective} (think 'impact' {currentTasksParents ? currentTasksParents[0]?.objective : ""}):</p>
        
        <div className="">
          <div className="flex justify-between">
            {
              new Array(11).fill(1).map((_, i) => (
                <div className="flex flex-col items-center" key={i}>
                  <label htmlFor="">{i * 10}%</label>
                  <input type="radio" onChange={handleChange} value={i * 10} name='okrGradePercentage' />
                </div>
              ))
            }
          </div>
          <div className="flex mt-2">
            {
              progress.map(item => (
                <div key={item.lavel} className={`w-[${item.percentage}%] h-2`}>
                  <p className={`${item.bg} h-2 mb-1`} />
                  <p className='text-center text-xs'>{item.lavel}</p>
                </div>
              ))
            }
          </div>
        </div>
      </div>

      <p className="text-top">
        Attention! The scheduled category of improvement for your current task has expired. 
        Please select one of the following options:
      </p>

      <div className="flex flex-col gap-2">
        <button
          className='py-2 bg-green-600 text-white font-bold rounded-md'
          onClick={() => dispatch(setUpcomingTaskModalOpen(false))}
        >
          1. Continue <span className='bg-blue-600 px-2 py-1 rounded'>{currentTask ? currentTask.objective : ""}</span> from <span className='bg-blue-600 px-2 py-1 rounded'>{currentTasksParents ? currentTasksParents[0]?.objective : ""}</span>
        </button>
        <button
          className='py-2 bg-green-600 text-white font-bold rounded-md'
          onClick={e.handleSubmit}
        >
          2. Start <span className='bg-blue-600 px-2 py-1 rounded'>{upcomingTask ? upcomingTask.objective : ""}</span> from <span className='bg-blue-600 px-2 py-1 rounded'>{upcomingTasksParent ? upcomingTasksParent.map((item:any) => item.objective).join("/") : ""}</span>
        </button>
      </div>
    </>
  );
};

