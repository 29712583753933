import React, { useEffect, useState } from "react";
import {Modal} from "../Modal/Index";
import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";
import { setAddNodeModalOpen, setNavigatePointerNodeId, setParentTaskCompleteAlertModalOpen, setUpcomingTaskModalOpen } from "../../store/actions/globalStates";
import { RootState } from "../../store/store";
import { getTreeData } from "../../pages/ICICLETree/Index";



export const ParentTaskCompleteAlertModal: React.FC = () => {
  const dispatch:any = useDispatch();
  const isParentTaskCompleteAlertModalOpen = useSelector((state: RootState) => state.globalStates.isParentTaskCompleteAlertModalOpen); // Adjust type as necessary

  const handleSubmit = () => {
    // e.resetForm();
    // Add your submit logic here
  };

  const onEscape = () => {
    // Handle escape key functionality here
  }

  return (
    <Modal
      saveBtnText=""
      onSubmit={handleSubmit}
      isOpen={isParentTaskCompleteAlertModalOpen}
      setIsOpen={(bool) => { dispatch(setUpcomingTaskModalOpen(bool)); }}
      hideBtns={true}
      onEscape={onEscape}
      showTopArrow={false}
    >
      <Formik
        initialValues={{ isWorking: "Yes", okrGradePercentage: 0, extraMinutes: 0 }}
        onSubmit={handleSubmit}
        validate={() => {}}
      >
        {({ handleSubmit }) => <Form e={{ handleSubmit }} />}
      </Formik>
    </Modal>
  );
}




interface FormProps {
  e: any; // Adjust type as necessary
}

const Form: React.FC<FormProps> = ({ e }) => {    
  return (
    <form className="w-full">
      <FormData e={e} />
    </form>
  )
}


interface FormDataProps {
  e: any; // Adjust type as necessary
}

const FormData: React.FC<FormDataProps> = ({ e }) => {
  const dispatch:any = useDispatch();
  const parentId = useSelector((state: RootState) => state.globalStates.parentId); // Adjust type as necessary
  const tree = useSelector((state: RootState) => state.tree); // Adjust type as necessary
  const [parent, setParent] = useState<any>(null);

  
  // const parent = currentTasksParents.find((p: any) => p._id === parentId); // Adjust type as necessary
  // const manuallyScheduleNodeId = useSelector((state: RootState) => state.globalStates.manuallyScheduleNodeId); // Adjust type as necessary
  // const wereYouWorking = useSelector((state: RootState) => state.globalStates.wereYouWorking); // Adjust type as necessary

  const handleAddNewTask = (e:any) => {
    e.preventDefault();
    // dispatch(setParentIdOfNodeTobeCreated(parent._id));
    dispatch(setParentTaskCompleteAlertModalOpen(false));
    dispatch(setAddNodeModalOpen(true));
    
    // const isRecurring = !!currentTask.recurring.frequency;
    // const ed = currentTask.taskExploitationDurationAsArray.reduce((a: number, b: any) => {
    //   if (isRecurring) {
    //     const isToday = moment(b.startTime).isBetween(moment().startOf("day"), moment().endOf("day"));
    //     return isToday ? a + moment(b.endTime).diff(moment(b.startTime), "seconds") : a; 
    //   }
    //   return a + moment(b.endTime).diff(moment(b.startTime), "seconds");   
    // }, 0);

    // const et = (currentTask.estimatedTaskDuration * 60) - ed;
    // const popupCameUpTime = moment(currentTask?.startTime).add(et, "seconds").valueOf();

    // if (currentTask?.startTime) {
    //   const endTime = wereYouWorking ? moment().valueOf() : popupCameUpTime;
    //   dispatch(completeTask(currentTask._id, currentTask.nodeProductivityValue,endTime.toString()));
    //   dispatch(setStartTime(null)); 
    // } else {
    //   dispatch(completeTask(currentTask._id,currentTask.nodeProductivityValue));
    // }

    // if (manuallyScheduleNodeId && manuallyScheduleNodeId[manuallyScheduleNodeId.length - 1] === currentTask._id) {
    //   const remainingIds = manuallyScheduleNodeId.filter((id:string) => id !== currentTask._id);
    //   dispatch(setManuallyScheduledNodeId(remainingIds.length ? remainingIds : null));
    // }

    // dispatch(setCurrentTask(null));
    // dispatch({ action: SET_WERE_YOU_WORKING, payload: false });
  }

  const handleCompleteTask = (e:any) => {
    e.preventDefault();
    dispatch(setParentTaskCompleteAlertModalOpen(false));

    const treeData = getTreeData();
    dispatch(setNavigatePointerNodeId(treeData.data._id));
  }

  useEffect(() => {
    function visit(d:any){
      if(d._id  === parentId){
        setParent(d);
        return;
      }
      if(d.children){
        d.children.forEach((child:any) => visit(child));
      }
    }
    visit(tree);
  }, [tree,parentId]);

  return (
    <>
      <p className="text-top">Excellent, you have completed all the current tasks for {parent?.objective}.</p>

      <div className="flex flex-col gap-2">
        <button onClick={handleCompleteTask} className='py-2 bg-green-600 text-white font-bold rounded-md'>
          Mark {parent?.objective} as completed
        </button>
        <button onClick={handleAddNewTask} className='py-2 bg-green-600 text-white font-bold rounded-md'>
          Add new task to {parent?.objective}
        </button>
      </div>
    </>
  );
}



