import React from 'react'

export function NoAccess() {
  return (
    <div className='absolute left-0 top-[64px] h-[calc(100vh-64px)] w-[100vw] bg-white'>
      <div className="w-full h-full flex flex-col justify-center items-center">
          <h1 className='text-2xl mb-1 font-bold text-gray-800'>You do not have permission to use the system</h1>
          <p className='text-gray-400'>Please contact us at <a className='text-blue-400 underline' href="mailto:howard@10x.systems">howard@10x.systems</a> for assistance.</p>
      </div>
    </div>
  )
}
