import React, { useEffect, useState } from 'react'
import { Modal } from './Index'
import { Button } from '../Buttons/Button';
import { Triangle } from '../Triangle';
import { useStartEndTime } from './useStartEndTime';
import { ParentNodeObjective } from './TaskModal';


interface IMissionModal {
  setIsOpen: any;
  setNodeObj: any;
  objectiveDynamicProperties?: any;
}

const screenNames = ['WELCOME',"PROJECT_TITLE","WHERE","WHAT","WHO","WHEN","WOW"] as const;
type TScreenName = typeof screenNames[number];

interface ITimeSpent {
  startTime: Date,
  endTime: Date,
  screenName: TScreenName;
}

interface Idata {
  boldActions: string;
  transformativeGoals: string;
  highImpactOutcomes: string;
  timeFrames: string;
  rating: number;
  timeSpent: ITimeSpent[];
}

type TypeSetData = React.Dispatch<React.SetStateAction<Idata>>

export const MoonShotModal: React.FC<IMissionModal> = ({setIsOpen,setNodeObj,objectiveDynamicProperties})  => {
  const [currentScreen, setCurrentScreen] = useState(1);
  const [data, setData] = useState<Idata>({
    boldActions: "",
    transformativeGoals: "",
    highImpactOutcomes: "",
    timeFrames: "",
    rating: 50,
    timeSpent: []
  });


 
  useEffect(() => {
    if(objectiveDynamicProperties){
      setData({
        boldActions: objectiveDynamicProperties.boldActions || "",
        transformativeGoals: objectiveDynamicProperties.transformativeGoals || "",
        highImpactOutcomes: objectiveDynamicProperties.highImpactOutcomes || "",
        timeFrames: objectiveDynamicProperties.timeFrames || "",
        rating: objectiveDynamicProperties.rating || 50,
        timeSpent: objectiveDynamicProperties.timeSpent || []
      });
    }
  }, [objectiveDynamicProperties]);

  return (
    <>
        <Modal
          saveBtnText="Create"
          onSubmit={()=>{}}
          isOpen={true}
          setIsOpen={setIsOpen}
          hideBtns={true}
          onEscape={() => {setIsOpen(false)}}
        >
          <> 
            {
              currentScreen === 1 && <Welcome data={data} setCurrentScreen={setCurrentScreen} setData={setData}/>
            }
            {
              currentScreen === 2 && <BoldActions data={data} setData={setData} setCurrentScreen={setCurrentScreen}/>
            }
            {
              currentScreen === 3 && <TransformativeGoals data={data} setData={setData} setCurrentScreen={setCurrentScreen}/>
            }
            {
              currentScreen === 4 && <HighImpactOutcomes data={data} setData={setData} setCurrentScreen={setCurrentScreen}/>
            }
            {
              currentScreen === 5 && <TimeFrames data={data} setData={setData} setCurrentScreen={setCurrentScreen}/>
            }
            {
              currentScreen === 6 && <Rating setNodeObj={setNodeObj} setIsOpen={setIsOpen} data={data} setData={setData} setCurrentScreen={setCurrentScreen}/>
            }
              
          </>
        </Modal>
    </>
  )
}


interface ITitle {
  data: Idata;
}

const Title: React.FC<ITitle> = ({data}) => {
  return <h2 className='text-center font-bold text-xl text-gray-400'>Craft Your Moonshot!</h2>
}


interface IInputField {
  labelText: string;
  smallText: string;
  name: string;
  placeHolder: string;
  id: string;
  value: string;
  onChange: any;
  children?: any;
  setIsHighlighted: any;
}

const InputField: React.FC<IInputField> = ({labelText,smallText,name,placeHolder,id,value,onChange,children,setIsHighlighted}) => {

  const handleFocus = (e:React.FocusEvent<HTMLInputElement>) => {
    e.preventDefault();
    setIsHighlighted(true);
  }


  useEffect(() => {
    document.addEventListener("click",(e: any) => {
      if(e.target.tagName !== "INPUT"){
        setIsHighlighted(false);
      }
    })
    
    return () => {
      document.removeEventListener("click",()=>{});
    };
  }, [setIsHighlighted]);
  

  return <div className='relative'>
            <div className="flex flex-col gap-1">
              <label className='text-gray-400 font-bold' htmlFor="">{labelText}</label>
                <input 
                  onFocus={handleFocus} 
                  // onBlur={handleBlur} 
                  value={value} 
                  onChange={onChange} 
                  type="text" 
                  placeholder={placeHolder} 
                  name={name} 
                  id={id} 
                  className='border p-2 rounded-md'
                />
              <small className='text-gray-400'>{smallText}</small>
            </div>
            {children}
        </div>
}


interface INextAndBackButton {
  nextClick: any;
  backClick: any;
  backText?: any;
  nextText?: any;
}

const NextAndBackButton: React.FC<INextAndBackButton> = ({nextClick,backClick,backText,nextText}) => {


  return <>
      <div className="flex gap-4">
          <Button onClick={backClick} style={{width: "100%",marginTop: "40px",background: "#0000ff"}}>{backText || "Back"}</Button>
          <Button onClick={nextClick} style={{width: "100%",marginTop: "40px"}}>{nextText || "Next"}</Button>
      </div>
  </>
}


interface IDropDown {
  list: string[],
  value: string;
  setData: any;
  isShow: boolean;
}

const DropDown: React.FC<IDropDown> = ({list,value,setData,isShow}) => {
  list = list.filter(item => item.toLowerCase().includes(value.toLowerCase()));
 
  
  if(!isShow){
    return <></>;
  }

  const handleClick = (e:React.MouseEvent<HTMLDivElement>,item:string) => {
    setData(item);
  }
  
  return <div className='absolute top-[72px] left-0 border bg-white w-full rounded-md z-[10]'>
            <div className="w-full h-full flex flex-col max-h-[150px] overflow-auto py-2 text-gray-400">
                 {
                    list.map((item,idx) => <div onClick={(e) => handleClick(e,item)} key={idx} className='hover:bg-gray-100 px-4 py-1 flex items-center cursor-pointer'>{item}</div>)
                 }
            </div>
        </div>
}


interface IPreView {
  value: string;
}

const PreView: React.FC<IPreView> = ({value}) => {


  return <>
      <div className='min-h-[44px] border border-2 p-2  text-center text-gray-400 '>
        {value}
      </div>
  </>
}


interface IWelcome {
  setCurrentScreen: any;
  setData: TypeSetData;
  data: Idata;
}


const Welcome: React.FC<IWelcome> = ({setCurrentScreen,setData,data}) => {
  useStartEndTime(setData,"WELCOME");



  return <>
      <Title data={data}/>
      {/* <Steps steps={STEPS} completedSteps={0}/> */}
      <p className='text-gray-400 text-center'>
          Imagine your mission as your guiding star!
      </p>
      
      <div className="">
          <ParentNodeObjective/>

          <Triangle topAngle={50} text='big impact on'/>

          <div className="border flex flex-col items-center py-2">
            <h1 className='text-3xl font-bold  text-gray-400'>Your Moonshot Goal</h1>
            <small className=' text-gray-600'>e.g. Transform healthcare accessibility worldwide</small>
          </div>
      </div>

      <p className='text-gray-400'>
          Now we're going to craft an ambitious and
          transformative Moonshot goal that directly
          drives it's progress!
      </p>

      <Button onClick={() => setCurrentScreen(2)} style={{width: "100%",marginTop: "40px"}}>Next</Button>
  </>
}


interface IChange {
  setCurrentScreen: any;
  data: Idata;
  setData: any;
}

const BoldActions: React.FC<IChange> = ({setCurrentScreen,data,setData}) => {
  const [list] = useState([
    "Launch", "Create", "Build", "Scale", "Disrupt", "Expand",
  "Lead", "Develop", "Elevate", "Innovate"
  ]);
  const [isHighlighted, setIsHighlighted] = useState(false);
  useStartEndTime(setData,"BoldActions".toUpperCase());

  return <>

      <div>
          <ParentNodeObjective/>
          <Triangle text='big impact on' topAngle={50}/>
          <PreView value={data.boldActions}/>
          <Triangle text='Bold action' topAngle={50}/>
          <InputField
              labelText=''
              smallText="This is action is the foundation of your Moonshot Objective."
              name='boldActions'
              placeHolder='Enter a big change  that will make big progress on the mission'
              id='boldActions'
              value={data.boldActions}
              onChange={(e: any) => setData((prev:Idata) => {return {...prev,boldActions: e.target.value}})}
              setIsHighlighted={setIsHighlighted}
          >
            <DropDown 
              list={list} 
              value={data.boldActions}
              setData={(str: string) => setData((prev:Idata) => {return {...prev,boldActions: str}})}
              isShow={isHighlighted}
            />
          </InputField>  
      </div>

      <NextAndBackButton
          backClick={() => setCurrentScreen(1)}
          nextClick={() => setCurrentScreen(3)}
      />
  </>
}


interface IImpact {
  setCurrentScreen: any;
  data: Idata;
  setData: any;
}

const TransformativeGoals: React.FC<IImpact> = ({setCurrentScreen,data,setData}) => {
  const [list] = useState([
    "Scalable Platform", "Customer-Centric Model", "Strategic Framework",
  "Product Ecosystem", "Digital Presence", "Agile Processes",
  "Operational Strategy", "Revenue Stream", "Growth Engine",
  "Competitive Advantage"
  ]);
  const [isHighlighted, setIsHighlighted] = useState(false);
  useStartEndTime(setData,"TransformativeGoals".toUpperCase());

  return <>
      <div>
          <ParentNodeObjective/>
          <Triangle text='big impact on' topAngle={50}/>
          <PreView value={`${data.boldActions} ${data.transformativeGoals}`}/>
          <Triangle text='Big change' topAngle={60}/>
          <InputField
              labelText='What’s the big, transformational goal you want to achieve?'
              smallText="This is the core of your objective. What transformational outcome do you aspire to achieve?"
              name='transformativeGoals'
              placeHolder='Enter the big impact you want'
              id='transformativeGoals'
              value={data.transformativeGoals}
              onChange={(e: any) => setData((prev:Idata) => {return {...prev,transformativeGoals: e.target.value}})}
              setIsHighlighted={setIsHighlighted}
          >
            <DropDown 
              list={list} 
              value={data.transformativeGoals}
              setData={(str: string) => setData((prev:Idata) => {return {...prev,transformativeGoals: str}})}
              isShow={isHighlighted}
            />
          </InputField> 
      </div>

      <NextAndBackButton
          backClick={() => setCurrentScreen(2)}
          nextClick={() => setCurrentScreen(4)}
      />
  </>
}


interface IWho {
  setCurrentScreen: any;
  data: Idata;
  setData: any;
}

const HighImpactOutcomes: React.FC<IWho> = ({setCurrentScreen,data,setData}) => {
  const [list] = useState([
      "for Maximum Results",
      "for Global Success",
      "for Future Growth",
      "for Enhanced Performance",
      "for Long-Term Impact",
      "for Customer Delight",
      "for Market Expansion",
      "for Measurable Outcomes",
      "for Scalable Impact",
      "for Transformative Change",
      "for Team Empowerment",
      "for Sustainable Value",
      "for Competitive Edge",
      "for Breakthrough Success",
      "for Holistic Growth",
      "to Achieve Profitability",
      "to Drive Early Revenue",
      "to Reach Market Fit",
      "to Secure Investors",
      "to Build User Trust",
      "to Gain Market Share",
      "to Ensure Scalability",
      "to Boost Customer Engagement",
      "to Validate the Business Model",
      "to Deliver Value Faster",
      "to Simplify Operations",
      "to Drive Customer Loyalty",
      "to Outperform Competitors",
      "to Create Sustainable Growth",
      "to Establish Market Leadership"
  ]);
  const [isHighlighted, setIsHighlighted] = useState(false);
  useStartEndTime(setData,"HighImpactOutcomes".toUpperCase());

  return <>
      <div className="">
          <ParentNodeObjective/>

          <Triangle text='big impact on' topAngle={50}/>

          <PreView value={`${data.boldActions} ${data.transformativeGoals} ${data.highImpactOutcomes}`}/>

          <Triangle text='' topAngle={70}/>

          <InputField
              labelText='Who will benefit from this change?'
              smallText="Think about the long-term impact. Who benefits, and what lasting value will you create?"
              name='highImpactOutcomes'
              placeHolder='Enter who will benefit'
              id='highImpactOutcomes'
              value={data.highImpactOutcomes}
              onChange={(e: any) => setData((prev:Idata) => {return {...prev,highImpactOutcomes: e.target.value}})}
              setIsHighlighted={setIsHighlighted}
          >
            <DropDown 
              list={list} 
              value={data.highImpactOutcomes}
              setData={(str: string) => setData((prev:Idata) => {return {...prev,highImpactOutcomes: str}})}
              isShow={isHighlighted}
            />
          </InputField>
      </div>


      <NextAndBackButton
          backClick={() => setCurrentScreen(3)}
          nextClick={() => setCurrentScreen(5)}
      />
  </>
}



interface ITime {
  setCurrentScreen: any;
  data: Idata;
  setData: any;
}

const TimeFrames: React.FC<ITime> = ({setCurrentScreen,data,setData}) => {
  const [list] = useState([
        "Revenue Growth", "Market Leadership", "Client Retention",
        "Customer Acquisition", "Global Reach", "Cost Reduction",
        "Team Productivity", "Innovation Pipeline", "Brand Equity",
        "Industry Disruption"
  ]);
  const [isHighlighted, setIsHighlighted] = useState(false);
  useStartEndTime(setData,"TimeFrames".toUpperCase());

  return <>
      <div className="">
          <ParentNodeObjective/>
          
          <Triangle text='big impact on' topAngle={50}/>

          <PreView value={`${data.boldActions} ${data.transformativeGoals} ${data.highImpactOutcomes} ${data.timeFrames}`}/>

          <Triangle text='Moonshot TimeFrame' topAngle={75}/>

          <InputField
              labelText='Enter a time frame'
              smallText="Adding a timeframe helps you stay focused and track progress."
              name='timeFrames'
              placeHolder='Enter a time frame'
              id='timeFrames'
              value={data.timeFrames}
              onChange={(e: any) => setData((prev:Idata) => {return {...prev,timeFrames: e.target.value}})}
              setIsHighlighted={setIsHighlighted}
            >
              <DropDown 
                list={list} 
                value={data.timeFrames}
                setData={(str: string) => setData((prev:Idata) => {return {...prev,timeFrames: str}})}
                isShow={isHighlighted}
              />
          </InputField>
      </div>


      <NextAndBackButton
          backClick={() => setCurrentScreen(4)}
          nextClick={() => setCurrentScreen(6)}
      />
  </>
}

interface IRating   {
  setCurrentScreen: any;
  data: Idata;
  setData: any;
  setIsOpen: any;
  setNodeObj: any;
}

const Rating: React.FC<IRating> = ({setCurrentScreen,data,setData,setIsOpen,setNodeObj}) => {
  const [finalString, setFinalString] = useState(`${data.boldActions} ${data.transformativeGoals} ${data.highImpactOutcomes} ${data.timeFrames}`);
  const {startTime} = useStartEndTime(setData,"RATING");
  const getText = (value: number) => {
     if(value < 40){
        return "It looks like this Moonshot could be refined. What would make it more exciting or meaningful for you?";
     }
     if(value > 40 && value < 70){
        return "Does this feel aligned with your goals? You can refine it for more clarity or move forward."
     }
     return "Great job! This Moonshot looks ready to guide your next steps!";
  }

  const handleSubmit = () => {
    setNodeObj((prev:any) => {
      const endTime = new Date();
      return {
        ...prev,
        objective: finalString,
        objectiveDynamicProperties: {
          ...data,
          timeSpent: [...data.timeSpent,{startTime,endTime,screenName: "RATING"}]
        }
      }
    });
    setIsOpen(false);
  }



  return <div>
      <div className="">
          <ParentNodeObjective/>
          <Triangle text='big impact on' topAngle={50}/>
          {/* <Steps steps={STEPS} completedSteps={5}/> */}
          <input className='border border-2 p-2 text-center text-gray-400 w-full font-bold' type="text" value={finalString} onChange={(e) => setFinalString(e.target.value)}/>
          {/* <PreView value={`To ${data.what} ${data.where} for ${data.highImpactOutcomes} ${data.when}`}/> */}
      </div>
      
      <h3 className='text-gray-400 text-center mt-8'>You Moonshot is nearly ready...</h3>
      <p className='text-gray-400 text-center mt-2'>Let's check how  inspired do you feel?</p>
      <h3 className='text-gray-400 font-bold text-center mt-2'>It makes me want to do:</h3>

      <div className="flex items-center gap-2 text-gray-400 mb-4 mt-2">
          <p className='text-nowrap'>Some Dusting.</p>
          <input type="range" min="1" max="100" value={data.rating} onChange={(e) => setData((prev: Idata) => {return {...prev,rating: Number(e.target.value)}})} className="w-full" id="myRange"/>
          <p className='text-nowrap'>A Victory Roar!</p>
      </div>

      <p className='text-gray-400 text-center'>{getText(data.rating)}</p>

      <NextAndBackButton
          backClick={() => setCurrentScreen(2)}
          nextClick={handleSubmit}
          backText={"Refine MoonShot"}
      />
  </div>
}





