import { NavLink, useLocation } from 'react-router-dom';


interface NavItemProps {
    href: string;
    name: string;
    displayName: string;
    children: any;
}

export function NavItem({name,href,displayName,children}:NavItemProps) {
    const {pathname} = useLocation();

  return (
    <li>
        <NavLink
          to={`${href}`}
          className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${
            pathname.includes(name) && 'bg-graydark dark:bg-meta-4'
          }`}
        >
          {children}
          {displayName}
        </NavLink>
    </li>
  )
}
