
export const APP_VERSION = "{{460}}";

const version = 'api/v1';

let root = "http://localhost:5000";

if(window.location.host.split(":")[0] === "localhost"){
    root = "http://localhost:5000";
}
else {
    root = "https://tenxproductivityreactapp.herokuapp.com";
}


export const DOMAIN_NAME = `${root}/${version}`;


export const NODE_TYPES = ["PROJECT_MISSION","MOONSHOT_OBJECTIVE","OBJECTIVE","KEY_RESULT","TASK"];








  
  































