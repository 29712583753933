import React, { useEffect } from 'react';
import {
  AddNodeModal,
  UpdateNodeModal,
  TimeAlertModal,
  UpCommingTaskModal,
  ParentTaskCompleteAlertModal,
} from "../../components";
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import { CreateCognipulseModal } from '../../components/Modal/CreateCognipulseModal';
import { CreateImpulseModal } from '../../components/Modal/CreateImpulseModal';
import { AddTreeModal } from '../../components/Modal/AddTreeModal';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setIsAdminDashboardView } from '../../store/actions';
import { CreatePhySelfie } from '../../components/Modal/CreatePhySelfieModal';
import { NoAccess } from '../NoAccess';


interface IndexProps {
  children?: React.ReactNode; // Adjust this type based on how you expect to use the children prop
  onlyModals?: boolean
}

const Index: React.FC<IndexProps> = ({ children,onlyModals }) => { 
  const isUpdateNodeModalOpen = useSelector((state:RootState) => state.globalStates.isUpdateNodeModalOpen);
  const isAddNodeModalOpen = useSelector((state:RootState) => state.globalStates.isAddNodeModalOpen);
  const isCreateCognipulseModalOpen = useSelector((state:RootState) => state.globalStates.isCreateCognipulseModalOpen);
  const isCreateImpulseModalOpen = useSelector((state:RootState) => state.globalStates.isCreateImpulseModalOpen);
  const isAddTreeModalOpen = useSelector((state:RootState) => state.globalStates.isAddTreeModalOpen);
  const isPhySelfieModalOpen = useSelector((state:RootState) => state.globalStates.isPhySelfieModalOpen);
  const loggedInUser = useSelector((state:RootState) => state.loggedInUser);
  const location = useLocation();
  const dispatch: AppDispatch = useDispatch();

  

  useEffect(() => {
    dispatch(setIsAdminDashboardView(location.pathname.includes("admin-dashboard")))
  }, [location,dispatch]);

 

  if(!loggedInUser?.hasAccess && location.pathname !== "/login" && location.pathname !== "/signup"){
    return <NoAccess/>
  }

  if(onlyModals){
    return <>
      {
        isAddNodeModalOpen && <AddNodeModal />
      }
      {
        isUpdateNodeModalOpen && <UpdateNodeModal />
      }
      {
        isCreateCognipulseModalOpen && <CreateCognipulseModal/>
      }
      {
        isCreateImpulseModalOpen && <CreateImpulseModal/>
      }
      {
        isAddTreeModalOpen && <AddTreeModal/>
      }
      {
        isPhySelfieModalOpen && <CreatePhySelfie/>
      }
      <TimeAlertModal />
      <UpCommingTaskModal />
      <ParentTaskCompleteAlertModal />
    </>
  }
  
  return (
    <div className='h-[calc(100vh-64px)] w-full text-black'>
      {children}
      {
        isAddNodeModalOpen && <AddNodeModal />
      }
      {
        isUpdateNodeModalOpen && <UpdateNodeModal />
      }
      {
        isCreateCognipulseModalOpen && <CreateCognipulseModal/>
      }
      {
        isCreateImpulseModalOpen && <CreateImpulseModal/>
      }
      {
        isAddTreeModalOpen && <AddTreeModal/>
      }
      {
        isPhySelfieModalOpen && <CreatePhySelfie/>
      }
      <TimeAlertModal />
      <UpCommingTaskModal />
      <ParentTaskCompleteAlertModal />
    </div>
  );
}

export default Index;




