import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  NavBar,
  SideBar
} from './layout';

import {
  Login,
  Signup,
  PageNotFound,
  SetWorkingTime,
  ICICLETree,
  Achievement,
  SetObjective,
  PastDeadlines,
  CapturePark,
  TrashCan,
  ExDuration,
  XLRate
} from './pages';

import {
  getLoggedInUser,
  getTreeData
} from "./store/actions";

import {
  PrivateRoute
} from './components';

import {generateFireBaseToken} from "./firebasePushNotification";
import { RootState } from './store/store';
import { isMobile } from 'react-device-detect';
import { CursorText } from './components/CursorText';
import { AdminDashboard } from './pages/Admin/Index';
import PageTitle from './pages/Admin/components/PageTitle';
import Tables from './pages/Admin/pages/Tables';
// import ECommerce from './pages/Admin/pages/Dashboard/ECommerce';
import Calendar from './pages/Admin/pages/Calendar';
import Profile from './pages/Admin/pages/Profile';
import FormElements from './pages/Admin/pages/Form/FormElements';
import FormLayout from './pages/Admin/pages/Form/FormLayout';
import Settings from './pages/Admin/pages/Settings';
import Chart from './pages/Admin/pages/Chart';
import Alerts from './pages/Admin/pages/UiElements/Alerts';
import Buttons from './pages/Admin/pages/UiElements/Buttons';
import SignIn from './pages/Admin/pages/Authentication/SignIn';
import SignUp from './pages/Signup/Index';
import Users from './pages/Admin/pages/Users';




const App: React.FC = () => {
  const dispatch:any = useDispatch();
  const loggedInUser = useSelector((state: RootState) => state.loggedInUser);
  const cutNodeId = useSelector((state: RootState) => state.globalStates.cutNodeId);
  const copyNodeId = useSelector((state: RootState) => state.globalStates.copiedNodeId);
  const isShowCaptureUIOnMobile = useSelector((state: RootState) => state.globalStates.isShowCaptureUIOnMobile);
  const isAdminDashboardView = useSelector((state: RootState) => state.globalStates.isAdminDashboardView);


  useEffect(() => {
    if (!loggedInUser) {
      dispatch(getLoggedInUser());
    }
    if (loggedInUser) {
      dispatch(getTreeData());
    }
  }, [dispatch, loggedInUser]);

  useEffect(() => {
    generateFireBaseToken();
  }, []);




  return (
    <>
    <BrowserRouter>
      {
        !isAdminDashboardView && <NavBar />
      }
      
      <div style={{ cursor: (cutNodeId || copyNodeId) ? "copy" : "auto" }} className='flex flex-col md:flex-row'>
        {
          (!isShowCaptureUIOnMobile || !isMobile) && !isAdminDashboardView && <>
              <div className="bg-gray-400 h-[calc(100vh-64px)] overflow-y-auto max-[450px]:w-full w-[240px]">
                  <SideBar />
              </div>
          </>
        }
        <div className="md:flex-1 h-auto w-[calc(100vw-240px)] max-[450px]:w-full">
          <Routes>
            <Route path='/login' element={<Login />} />
            <Route path='/signup' element={<Signup />} />
            <Route path='/set-working-time' element={<PrivateRoute><SetWorkingTime /></PrivateRoute>} />
            <Route path='/achievement' element={<PrivateRoute><Achievement /></PrivateRoute>} />
            <Route path='/' element={<PrivateRoute><ICICLETree /></PrivateRoute>} />
            <Route path='/capture-park' element={<PrivateRoute><CapturePark /></PrivateRoute>} />
            <Route path='/set-objective' element={<PrivateRoute><SetObjective /></PrivateRoute>} />
            <Route path='/past-deadlines' element={<PrivateRoute><PastDeadlines /></PrivateRoute>} />
            <Route path='/trash-can' element={<PrivateRoute><TrashCan /></PrivateRoute>} />
            <Route path='/ex-duration/:taskId' element={<PrivateRoute><ExDuration /></PrivateRoute>} />
            <Route path='/xlrate' element={<PrivateRoute><XLRate /></PrivateRoute>} />
            <Route path='admin-dashboard' element={<AdminDashboard/>}>
                <Route
                  path="users"
                  element={
                    <>
                      <PageTitle title="10X Dashboard | Users" />
                      {/* <ECommerce /> */}
                      <Users />
                    </>
                  }
                />
                <Route
                  path="calendar"
                  element={
                    <>
                      <PageTitle title="Calendar | TailAdmin - Tailwind CSS Admin Dashboard Template" />
                      <Calendar />
                    </>
                  }
                />
                <Route
                  path="profile"
                  element={
                    <>
                      <PageTitle title="Profile | TailAdmin - Tailwind CSS Admin Dashboard Template" />
                      <Profile />
                    </>
                  }
                />
                <Route
                  path="forms/form-elements"
                  element={
                    <>
                      <PageTitle title="Form Elements | TailAdmin - Tailwind CSS Admin Dashboard Template" />
                      <FormElements/>
                    </>
                  }
                />
                <Route
                  path="forms/form-layout"
                  element={
                    <>
                      <PageTitle title="Form Layout | TailAdmin - Tailwind CSS Admin Dashboard Template" />
                      <FormLayout />
                    </>
                  }
                />
                <Route
                  path="tables"
                  element={
                    <>
                      <PageTitle title="Tables | TailAdmin - Tailwind CSS Admin Dashboard Template" />
                      <Tables />
                    </>
                  }
                />
                <Route
                  path="settings"
                  element={
                    <>
                      <PageTitle title="Settings | TailAdmin - Tailwind CSS Admin Dashboard Template" />
                      <Settings />
                    </>
                  }
                />
                <Route
                  path="chart"
                  element={
                    <>
                      <PageTitle title="Basic Chart | TailAdmin - Tailwind CSS Admin Dashboard Template" />
                      <Chart />
                    </>
                  }
                />
                <Route
                  path="ui/alerts"
                  element={
                    <>
                      <PageTitle title="Alerts | TailAdmin - Tailwind CSS Admin Dashboard Template" />
                      <Alerts />
                    </>
                  }
                />
                <Route
                  path="ui/buttons"
                  element={
                    <>
                      <PageTitle title="Buttons | TailAdmin - Tailwind CSS Admin Dashboard Template" />
                      <Buttons />
                    </>
                  }
                />
                <Route
                  path="auth/signin"
                  element={
                    <>
                      <PageTitle title="Signin | TailAdmin - Tailwind CSS Admin Dashboard Template" />
                      <SignIn />
                    </>
                  }
                />
                <Route
                  path="auth/signup"
                  element={
                    <>
                      <PageTitle title="Signup | TailAdmin - Tailwind CSS Admin Dashboard Template" />
                      <SignUp />
                    </>
                  }
                />
            </Route>
            <Route path='*' element={<PageNotFound />} />
          </Routes>
        </div>
      </div>
      <Routes>
        <Route></Route>
      </Routes>
      {
        (cutNodeId || copyNodeId) && <CursorText/>
      }
    </BrowserRouter>
    </>
  );
}

export default App;





