import React from 'react';

type ButtonProps = {
  label: string; 
  onClick: (e:React.MouseEvent<HTMLButtonElement>) => void;
  isDisabled?: boolean; 
  className?: string;
  style?: React.CSSProperties;
  buttonType?: "rect" | "rounded";
};

export const Button: React.FC<ButtonProps> = (props) => {

  const {
        label,
        onClick,
        isDisabled = false,
        className = '',
        style={},
        buttonType = "rect"
    } = props;  

  
  if(buttonType === "rounded"){
    return <RoundedButton label={label} onClick={onClick}/>
  }  

  const baseStyle = 'w-auto px-5 py-2 flex justify-center font-bold items-center outline-none bg-green-500 rounded-md text-white shadow-lg transform active:scale-90 active:opacity-50 transition-transform';


  return (
    <button
      className={`${baseStyle}  ${className}`}
      onClick={onClick}
      disabled={isDisabled}
      style={style}
    >
      {label}
    </button>
  );
};



type RoundedButtonProps = {
    label: string;
    title?: string;
    style?: React.CSSProperties,
    onClick: any; 
}

const RoundedButton: React.FC<RoundedButtonProps> = ({title="",style={},onClick,label}) => {

    return <>
        <button
	            title={title}
				style={style}
				onClick={onClick}
				className="p-2 flex justify-center items-center outline-none bg-green-500 rounded-full text-white shadow-lg transform active:scale-75 active:bg-green-800 transition-transform"
			>
				{label}
		</button>
    </>
}

