import { useEffect, useState } from "react";
import { deleteUserByIdAdmin, getUserByIdAdmin, IUser, IUserEdit, updateUserByIdAdmin } from "../../../../store/actions";
import { toast } from "react-toastify";



interface EditUserModalProps {
    setIsShowUserEditModal: any;
    idOfUserToEdit: string;
    setUsers: React.Dispatch<React.SetStateAction<IUser[]>>
}

export function EditUserModal({setIsShowUserEditModal,idOfUserToEdit,setUsers}:EditUserModalProps) {
    const [user, setUser] = useState<null | IUser>(null);
    

    

    const handleUserChange = (e: any) => {
        setUser((prev:any) => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        })
    }

    const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        const userData: IUserEdit = {
            name: user?.name || "",
            email: user?.email || "",
            role: user?.role || "user",
            hasAccess: user?.hasAccess as boolean
        }
        

        const res = await updateUserByIdAdmin(idOfUserToEdit,userData);

        if(res.success){
            setUsers(prev => {
                return prev.map(preUser => preUser._id === idOfUserToEdit? {...preUser,...res.user}:preUser)
            })
            setIsShowUserEditModal(false);
        }
        else {
            toast.error(res.message);
        }
    }

    const handleDelete = async (e:React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if(window.confirm("Deleting user account is permanent and cannot be undone")){
            const res = await deleteUserByIdAdmin(idOfUserToEdit)
            
            if(res.success){
                setUsers(prev => {
                    return prev.filter(preUser => preUser._id !== idOfUserToEdit);
                })
                setIsShowUserEditModal(false);
            }
            else {
                toast.error(res.message);
            }
        }
    }

    useEffect(() => {
        (async () => {
            if(idOfUserToEdit){
                const res = await getUserByIdAdmin(idOfUserToEdit);
                if(res.success){
                    setUser(res.user)
                }
            }
        })()

        return () => {
            
        };
    }, [idOfUserToEdit]);


    return (
        <div className='mx-auto px-4 py-8 sm:px-8'>
            <div className="flex items-center justify-between pb-6">
                    <div>
                        <h2 className="font-semibold text-gray-700">Edit User</h2>
                        <span className="text-xs text-gray-500">You can edit any details of this user</span>
                    </div>
                    <div className="flex items-center justify-between">
                        <div className="ml-10 space-x-8 lg:ml-40">
                        <button onClick={(e)=> {e.preventDefault();setIsShowUserEditModal(false);}} className="flex items-center gap-2 rounded-md bg-blue-600 px-4 py-2 text-sm font-semibold text-white focus:outline-none focus:ring hover:bg-blue-700">
                            Back
                        </button>
                        </div>
                    </div>
                    </div>
                    <div className="overflow-y-hidden rounded-lg border">
                        {
                            !user && <>Loadin..</>
                        }
                        {
                            user && <>
                                    <div className="w-full">
                                        <div className="w-full p-4 sm:p-12.5 xl:p-17.5">
                                        <div className="flex justify-end">
                                            <button onClick={handleDelete} className="mb-4 font-bold cursor-pointer rounded-lg border border-red-500 bg-red-500 p-2 text-white transition hover:bg-opacity-90">
                                                Delete
                                            </button>
                                        </div>
                                        <form className="flex flex-wrap gap-[2%]">
                                            <div className="mb-4 w-[49%]">
                                                <label htmlFor="name" className="mb-2.5 block font-medium text-black dark:text-white">
                                                    Name
                                                </label>
                                                <div className="relative">
                                                    <input
                                                    value={user.name}
                                                    type="text"
                                                    name="name"
                                                    id="name"
                                                    onChange={handleUserChange}
                                                    placeholder="Enter your Full Name"
                                                    className="w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 text-black outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                                                    />
                                                </div>
                                            </div>

                                            <div className="mb-4 w-[49%]">
                                                <label htmlFor="email" className="mb-2.5 block font-medium text-black dark:text-white">
                                                    Email
                                                </label>
                                                <div className="relative">
                                                    <input
                                                    value={user.email}
                                                    type="email"
                                                    name="email"
                                                    id="email"
                                                    onChange={handleUserChange}
                                                    placeholder="Enter your email"
                                                    className="w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 text-black outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                                                    />
                                                </div>
                                            </div>

                                            <div className="mb-4 w-[49%]">
                                                <label htmlFor="role" className="mb-2.5 block font-medium text-black dark:text-white">
                                                    Role
                                                </label>
                                                <div className="relative">
                                                    <select onChange={handleUserChange} value={user.role} name="role" id="role" className="w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 text-black outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary">
                                                        <option value="user">User</option>
                                                        <option value="admin">Admin</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="mb-4 w-[49%]">
                                                <label htmlFor="role" className="mb-2.5 block font-medium text-black dark:text-white">
                                                    Has Access
                                                </label>
                                                <div className="relative">
                                                    <div>
                                                        <label
                                                            htmlFor="toggle3"
                                                            className="flex cursor-pointer select-none items-center"
                                                        >
                                                            <div className="relative">
                                                            <input
                                                                type="checkbox"
                                                                id="toggle3"
                                                                className="sr-only"
                                                                onChange={() => {
                                                                    setUser((prev:any) => {
                                                                        return {
                                                                            ...prev,
                                                                            hasAccess: !prev.hasAccess
                                                                        }
                                                                    })
                                                                }}
                                                            />
                                                            <div className="block h-8 w-14 rounded-full bg-meta-9 dark:bg-[#5A616B]"></div>
                                                            <div
                                                                className={`dot absolute left-1 top-1 flex h-6 w-6 items-center justify-center rounded-full bg-white transition ${
                                                                user.hasAccess && '!right-1 !translate-x-full !bg-primary dark:!bg-white'
                                                                }`}
                                                            >
                                                                <span className={`hidden ${user.hasAccess && '!block'}`}>
                                                                <svg
                                                                    className="fill-white dark:fill-black"
                                                                    width="11"
                                                                    height="8"
                                                                    viewBox="0 0 11 8"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                    d="M10.0915 0.951972L10.0867 0.946075L10.0813 0.940568C9.90076 0.753564 9.61034 0.753146 9.42927 0.939309L4.16201 6.22962L1.58507 3.63469C1.40401 3.44841 1.11351 3.44879 0.932892 3.63584C0.755703 3.81933 0.755703 4.10875 0.932892 4.29224L0.932878 4.29225L0.934851 4.29424L3.58046 6.95832C3.73676 7.11955 3.94983 7.2 4.1473 7.2C4.36196 7.2 4.55963 7.11773 4.71406 6.9584L10.0468 1.60234C10.2436 1.4199 10.2421 1.1339 10.0915 0.951972ZM4.2327 6.30081L4.2317 6.2998C4.23206 6.30015 4.23237 6.30049 4.23269 6.30082L4.2327 6.30081Z"
                                                                    fill=""
                                                                    stroke=""
                                                                    strokeWidth="0.4"
                                                                    ></path>
                                                                </svg>
                                                                </span>
                                                                <span className={`${user.hasAccess && 'hidden'}`}>
                                                                <svg
                                                                    className="h-4 w-4 stroke-current"
                                                                    fill="none"
                                                                    viewBox="0 0 24 24"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    strokeWidth="2"
                                                                    d="M6 18L18 6M6 6l12 12"
                                                                    ></path>
                                                                </svg>
                                                                </span>
                                                            </div>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mb-5 w-full">
                                                <button onClick={handleSubmit} className="w-full cursor-pointer rounded-lg border border-primary bg-primary p-4 text-white transition hover:bg-opacity-90">
                                                    Save
                                                </button>
                                            </div>
                                        </form>
                                        </div>
                                    </div>
                            </>
                        }
                    </div>
        </div>
    )
}
