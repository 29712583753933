  interface ISteps {
    completedSteps: number,
    steps: string[]
  }
  
  export const Steps: React.FC<ISteps> = ({steps,completedSteps}) => {
 
  
    return <>
        <div className="">
            <h2 className="sr-only">Steps</h2>
            <div className="after:mt-4 after:block after:h-1 after:w-full after:rounded-lg after:bg-gray-200">
                <ol style={{display: "grid",gridTemplateColumns: `repeat(${steps.length}, 1fr)`}} className={`text-sm font-medium text-gray-500`}>
                    {
                        steps.map((step,idx) => {
                            const className1 = idx < completedSteps? "text-gray-400":"";
                            const className2 = idx < completedSteps? "bg-blue-600 text-white":"bg-gray-200 text-white";
  
                            if(idx === 0){
                                return <>
                                    <li key={idx} className={`relative flex justify-center ${className1}`}>
                                        <span
                                        className={`absolute -bottom-[1.75rem] left-1/2 -translate-x-1/2 rounded-full ${className2}`}
                                        >
                                        <svg
                                            className="size-5"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path
                                            fillRule="evenodd"
                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                            clipRule="evenodd"
                                            />
                                        </svg>
                                        </span>
  
                                        <span className="hidden sm:block"> {step} </span>
  
                                        <svg
                                        className="mx-auto size-6 sm:hidden"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                        />
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                        />
                                        </svg>
                                    </li>
                                </>
                            }
                            if(idx === steps.length-1){
                                return <>
                                    <li key={idx} className={`relative flex justify-center ${className1}`}>
                                        <span
                                        className={`absolute -bottom-[1.75rem] left-1/2 -translate-x-1/2 rounded-full ${className2}`}
                                        >
                                        <svg
                                            className="size-5"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path
                                            fillRule="evenodd"
                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                            clipRule="evenodd"
                                            />
                                        </svg>
                                        </span>
  
                                        <span className="hidden sm:block"> {step} </span>
  
                                        <svg
                                        className="mx-auto size-6 sm:hidden"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                        />
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                        />
                                        </svg>
                                    </li>
                                </>
                            }
                            return <>
                            <li className={`relative flex justify-center ${className1}`} key={idx}>
                                <span
                                className={`absolute -bottom-[1.75rem] left-1/2 -translate-x-1/2 rounded-full ${className2}`}
                                >
                                <svg
                                    className="size-5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                >
                                    <path
                                    fillRule="evenodd"
                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                    clipRule="evenodd"
                                    />
                                </svg>
                                </span>
  
                                <span className="hidden sm:block"> {step} </span>
  
                                <svg
                                className="mx-auto size-6 sm:hidden"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth="2"
                                >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                />
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                                </svg>
                            </li>
                            </>
                        })
                    }
                </ol>
            </div>
        </div>
    </>
  }