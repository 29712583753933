import React, { useEffect, useState } from 'react'
import { Modal } from './Index'
import { Steps } from '../Steps';
import { Button } from '../Buttons/Button';
import { Triangle } from '../Triangle';
import { useStartEndTime } from './useStartEndTime';


interface IMissionModal {
  setIsOpen: any;
  setNodeObj: any;
  objectiveDynamicProperties?: any;
}

const screenNames = ['WELCOME',"PROJECT_TITLE","WHERE","WHAT","WHO","WHEN","WOW"] as const;
type TScreenName = typeof screenNames[number];

interface ITimeSpent {
  startTime: Date,
  endTime: Date,
  screenName: TScreenName;
}


interface Idata {
  powerfulVerb: string;
  keyTransformationalConcept: string;
  ultimateBenefit: string;
  timeFrames: string;
  rating: number;
  title: string;
  timeSpent: ITimeSpent[];
}

type TypeSetData = React.Dispatch<React.SetStateAction<Idata>>

export const MissionModal: React.FC<IMissionModal> = ({setIsOpen,setNodeObj,objectiveDynamicProperties})  => {
  const [currentScreen, setCurrentScreen] = useState(1);
  const [data, setData] = useState<Idata>({
    powerfulVerb: "",
    keyTransformationalConcept: "",
    ultimateBenefit: "",
    timeFrames: "",
    rating: 50,
    title: '',
    timeSpent: []
  });

  

 
  useEffect(() => {
    if(objectiveDynamicProperties){
      setData({
        powerfulVerb: objectiveDynamicProperties.powerfulVerb || "",
        keyTransformationalConcept: objectiveDynamicProperties.keyTransformationalConcept || "",
        ultimateBenefit: objectiveDynamicProperties.ultimateBenefit || "",
        timeFrames: objectiveDynamicProperties.timeFrames || "",
        rating: objectiveDynamicProperties.rating || 50,
        title: objectiveDynamicProperties.title || "",
        timeSpent: objectiveDynamicProperties.timeSpent || []
      });
    }
  }, [objectiveDynamicProperties]);

  return (
    <>
        <Modal
          saveBtnText="Create"
          onSubmit={()=>{}}
          isOpen={true}
          setIsOpen={setIsOpen}
          hideBtns={true}
          onEscape={() => {setIsOpen(false)}}
        >
          <> 
            {
              currentScreen === 1 && <Welcome data={data} setCurrentScreen={setCurrentScreen} setData={setData}/>
            }
            {
              currentScreen === 2 && <ProjectTitle data={data} setData={setData} setCurrentScreen={setCurrentScreen}/>
            }
            {
              currentScreen === 3 && <PowerfulVerb data={data} setData={setData} setCurrentScreen={setCurrentScreen}/>
            }
            {
              currentScreen === 4 && <KeyTransformationalConcept data={data} setData={setData} setCurrentScreen={setCurrentScreen}/>
            }
            {
              currentScreen === 5 && <UltimateBenefit data={data} setData={setData} setCurrentScreen={setCurrentScreen}/>
            }
            {
              currentScreen === 6 && <TimeFrames data={data} setData={setData} setCurrentScreen={setCurrentScreen}/>
            }
            {
              currentScreen === 7 && <Wow setNodeObj={setNodeObj} setIsOpen={setIsOpen} data={data} setData={setData} setCurrentScreen={setCurrentScreen}/>
            }
              
          </>
        </Modal>
    </>
  )
}


interface ITitle {
  data: Idata;
}

const Title: React.FC<ITitle> = ({data}) => {
  return <h2 className='text-center font-bold text-xl text-gray-400'>Setting {data?.title? `${data?.title}'s`:"Your Project"} 'Mission'</h2>
}


interface IInputField {
  labelText: string;
  smallText: string;
  name: string;
  placeHolder: string;
  id: string;
  value: string;
  onChange: any;
  children?: any;
  setIsHighlighted: any;
}

const InputField: React.FC<IInputField> = ({labelText,smallText,name,placeHolder,id,value,onChange,children,setIsHighlighted}) => {

  const handleFocus = (e:React.FocusEvent<HTMLInputElement>) => {
    e.preventDefault();
    setIsHighlighted(true);
  }


  useEffect(() => {
    document.addEventListener("click",(e: any) => {
      if(e.target.tagName !== "INPUT"){
        setIsHighlighted(false);
      }
    })
    
    return () => {
      document.removeEventListener("click",()=>{});
    };
  }, [setIsHighlighted]);
  

  return <div className='relative'>
            <div className="flex flex-col gap-1">
              <label className='text-gray-400 font-bold' htmlFor="">{labelText}</label>
                <input 
                  onFocus={handleFocus} 
                  // onBlur={handleBlur} 
                  value={value} 
                  onChange={onChange} 
                  type="text" 
                  placeholder={placeHolder} 
                  name={name} 
                  id={id} 
                  className='border p-2 rounded-md'
                />
              <small className='text-gray-400'>{smallText}</small>
            </div>
            {children}
        </div>
}


interface INextAndBackButton {
  nextClick: any;
  backClick: any;
  backText?: any;
  nextText?: any;
}

const NextAndBackButton: React.FC<INextAndBackButton> = ({nextClick,backClick,backText,nextText}) => {


  return <>
      <div className="flex gap-4">
          <Button onClick={backClick} style={{width: "100%",marginTop: "40px",background: "#0000ff"}}>{backText || "Back"}</Button>
          <Button onClick={nextClick} style={{width: "100%",marginTop: "40px"}}>{nextText || "Next"}</Button>
      </div>
  </>
}


interface IDropDown {
  list: string[],
  value: string;
  setData: any;
  isShow: boolean;
}

const DropDown: React.FC<IDropDown> = ({list,value,setData,isShow}) => {
  list = list.filter(item => item.toLowerCase().includes(value.toLowerCase()));
 
  
  if(!isShow){
    return <></>;
  }
  

  const handleClick = (e:React.MouseEvent<HTMLDivElement>,item:string) => {
    setData(item);
  }
  
  return <div className='absolute top-full left-0 border bg-white w-full rounded-md z-[10]'>
            <div className="w-full h-full flex flex-col max-h-[150px] overflow-auto py-2 text-gray-400">
                 {
                    list.map((item,idx) => <div onClick={(e) => handleClick(e,item)} key={idx} className='hover:bg-gray-100 px-4 py-1 flex items-center cursor-pointer'>{item}</div>)
                 }
            </div>
        </div>
}


interface IPreView {
  value: string;
  title?: string;
}

const PreView: React.FC<IPreView> = ({value,title}) => {


  return <div>
      <h1 className='text-gray-400 font-bold'>{title}</h1>
      <div className='min-h-[44px] border border-2 p-2 rounded-md text-center text-gray-400 '>
        {value}
      </div>
  </div>
}


interface IWelcome {
  setCurrentScreen: any;
  setData: TypeSetData;
  data: Idata;
}

const STEPS = ["Where","What","Who","When","Wow!"]

const Welcome: React.FC<IWelcome> = ({setCurrentScreen,setData,data}) => {
  useStartEndTime(setData,"WELCOME");



  return <>
      <Title data={data}/>
      <Steps steps={STEPS} completedSteps={0}/>
      <p className='text-gray-400'>
          Defining your 'mission' is the big, inspiring
          'Why!' supporting what you do. It's also the
          first step to supercharge your focus, unlock
          your potential... it’s about connecting to what
          matters most to you and turning it into a
          powerful, actionable plan.
      </p>

      <p className='text-gray-400'>
          Let’s build a mission that excites you,
          challenges you, and pushes you to achieve
          more than you thought possible. Ready to
          start?
      </p>

      <Button onClick={() => setCurrentScreen(2)} style={{width: "100%",marginTop: "40px"}}>Next</Button>
  </>
}


interface IProjectTitle {
  setCurrentScreen: any;
  data: Idata;
  setData: any;
}

const ProjectTitle: React.FC<IProjectTitle> = ({setCurrentScreen,data,setData}) => {
  useStartEndTime(setData,"PROJECT_TITLE");

  return <>
      <Title data={data}/>
      {/* <Steps steps={STEPS} completedSteps={1}/> */}

      <InputField
          labelText='Project Title'
          smallText="Create a project title that's concise, memorable, and reflect its outcome."
          name='title'
          placeHolder='Enter your project title here e.g. Tech4Good'
          id='title'
          value={data.title}
          onChange={(e: any) => setData((prev:Idata) => {return {...prev,title: e.target.value}})}
          setIsHighlighted={() => {}}
      />


      <NextAndBackButton
          backClick={() => setCurrentScreen(1)}
          nextClick={() => setCurrentScreen(3)}
      />
  </>
}

interface IWhere {
  setCurrentScreen: any;
  data: Idata;
  setData: any;
}

const PowerfulVerb: React.FC<IWhere> = ({setCurrentScreen,data,setData}) => {
  const [list] = useState([
      "Innovate",
      "Empower",
      "Revolutionise",
      "Transform",
      "Build",
      "Drive",
      "Optimise",
      "Accelerate",
      "Scale",
      "Pioneer",
  ]);
  const [isHighlighted, setIsHighlighted] = useState(false);
  useStartEndTime(setData,"PowerfulVerb".toUpperCase());


  return <>
      <Title data={data}/>
      {/* <Steps steps={STEPS} completedSteps={1}/> */}
      
      <div className="">
          <PreView value={`${data.powerfulVerb}`} title='What would you like to do?'/>
          <Triangle text='Action' topAngle={50}/>
          <InputField
              labelText='Choose a word that reflects your goal. This sets the tone for your mission!'
              smallText='Are you aiming to inspire others or build something meaningful? Pick the word that feels right!'
              name='powerfulVerb'
              placeHolder='e.g. “Transform.”'
              id='powerfulVerb'
              value={data.powerfulVerb}
              onChange={(e: any) => setData((prev:Idata) => {return {...prev,powerfulVerb: e.target.value}})}
              setIsHighlighted={setIsHighlighted}
          >
              <DropDown 
                list={list} 
                value={data.powerfulVerb}
                isShow={isHighlighted} 
                setData={(item:string)=> setData((prev:Idata) => {return {...prev,powerfulVerb: item}})}
              />
          </InputField>
      </div>
      
      <NextAndBackButton
          backClick={() => setCurrentScreen(2)}
          nextClick={() => setCurrentScreen(4)}
      />
  </>
}


interface IWhat {
  setCurrentScreen: any;
  data: Idata;
  setData: any;
}

const KeyTransformationalConcept: React.FC<IWhat> = ({setCurrentScreen,data,setData}) => {
  const [list] = useState([
      "Operations",
      "Strategy",
      "Growth",
      "Leadership",
      "Culture",
      "Technology",
      "Vision",
      "Products",
      "Services",
      "Innovation",
  ]);
  const [isHighlighted, setIsHighlighted] = useState(false);
  useStartEndTime(setData,"KeyTransformationalConcept".toUpperCase());
  

  

  return <>
      <Title data={data}/>
      {/* <Steps steps={STEPS} completedSteps={2}/> */}
      
      <div className="">
          <PreView value={`${data.powerfulVerb} ${data.keyTransformationalConcept}`} title='What area do you want to focus on?'/>

          <Triangle text='Focus' topAngle={50}/>

          <InputField
              labelText='Is it about building confidence, fostering relationships, or creating innovation? Focus on what truly matters!'
              smallText='Pick the key idea that matters most to you. Think about where you’d like to make the biggest impact.'
              name='keyTransformationalConcept'
              placeHolder='e.g. Growth'
              id='keyTransformationalConcept'
              value={data.keyTransformationalConcept}
              onChange={(e:any) => {setData((prev:Idata) => {return {...prev,keyTransformationalConcept: e.target.value}})}}
              setIsHighlighted={setIsHighlighted}
          >
              <DropDown 
                list={list} 
                value={data.keyTransformationalConcept} 
                isShow={isHighlighted}
                setData={(item:string)=> setData((prev:Idata) => {return {...prev,keyTransformationalConcept: item}})}
              />
          </InputField>
      </div>
      

      <NextAndBackButton
          backClick={() => setCurrentScreen(3)}
          nextClick={() => setCurrentScreen(5)}
      />
  </>
}



interface IWho {
  setCurrentScreen: any;
  data: Idata;
  setData: any;
}

const UltimateBenefit: React.FC<IWho> = ({setCurrentScreen,data,setData}) => {
  const [list] = useState([
      "Profitability",
      "Market Share",
      "Sustainability",
      "Scalability",
      "Competitive Edge",
      "Customer Satisfaction",
      "Operational Excellence",
      "Revenue Growth",
      "Team Alignment",
      "Global Impact",
      "Moonshot",
  ]);
  const [isHighlighted, setIsHighlighted] = useState(false);
  useStartEndTime(setData,"UltimateBenefit".toUpperCase());



  return <>
      <Title data={data}/>
      {/* <Steps steps={STEPS} completedSteps={3}/> */}
      
      <div className="">
          <PreView title='What’s the big result you want?' value={`${data.keyTransformationalConcept} ${data.powerfulVerb} ${data.ultimateBenefit}`}/>

          <Triangle text='Outcome' topAngle={50}/>

          <InputField
              labelText='What’s the big win? Clarity, success, or something else? Pick what lights you up!'
              smallText='Choose the ultimate outcome that inspires you. This is the heart of your mission!'
              name='ultimateBenefit'
              placeHolder='e.g. Profitability'
              id='ultimateBenefit'
              value={data.ultimateBenefit}
              onChange={(e: any) => setData((prev:Idata) => {return {...prev,ultimateBenefit: e.target.value}})}
              setIsHighlighted={setIsHighlighted}
          >
              <DropDown 
                list={list} 
                value={data.ultimateBenefit} 
                isShow={isHighlighted}
                setData={(item:string)=> setData((prev:Idata) => {return {...prev,ultimateBenefit: item}})}
              />
          </InputField>  
      </div>
      

      <NextAndBackButton
          backClick={() => setCurrentScreen(4)}
          nextClick={() => setCurrentScreen(6)}
      />
  </>
}


interface IWhan {
  setCurrentScreen: any;
  data: Idata;
  setData: any;
}

const TimeFrames: React.FC<IWhan> = ({setCurrentScreen,data,setData}) => {
  const [list] = useState([
    "By 2025",
    "By 2030",
    "By 2050",
    "Within 1 Year",
    "Within 3 Years",
    "Within 5 Years",
    "Over the Next Decade",
    "By the End of the Decade",
    "In the Next 10 Years",
    "By Mid-Century",
    "By 2050 and Beyond",
    "In Future Generations",
    "As Soon As Possible",
    "Immediately",
    "Over Time",
    "In Our Lifetime",
    "For the Next Generation",
    "Forever",
    "For Years to Come",
    "For a Better Future"
  ]);
  const [isHighlighted, setIsHighlighted] = useState(false);
  useStartEndTime(setData,"TimeFrames".toUpperCase());

  return <>
      <Title data={data}/>
      {/* <Steps steps={STEPS} completedSteps={4}/> */}
      
      <div className="">
        <PreView value={`To ${data.keyTransformationalConcept} ${data.powerfulVerb} for ${data.ultimateBenefit} ${data.timeFrames}`}/>
        
        <Triangle text='Mission timeframe' topAngle={50}/>

        <InputField
            labelText='Who will benefit from this change?'
            smallText='Adding a timeframe helps you stay focused and track progress.'
            name='timeFrames'
            placeHolder='Enter a time frame'
            id='timeFrames'
            value={data.timeFrames}
            onChange={(e: any) => setData((prev:Idata) => {return {...prev,timeFrames: e.target.value}})}
            setIsHighlighted={setIsHighlighted}
        >
            <DropDown 
              list={list} 
              value={data.timeFrames} 
              isShow={isHighlighted}
              setData={(item:string)=> setData((prev:Idata) => {return {...prev,timeFrames: item}})}
            />
        </InputField>
      </div>
      

      <NextAndBackButton
          backClick={() => setCurrentScreen(5)}
          nextClick={() => setCurrentScreen(7)}
      />
  </>
}


interface IWow {
  setCurrentScreen: any;
  data: Idata;
  setData: any;
  setIsOpen: any;
  setNodeObj: any;
}

const Wow: React.FC<IWow> = ({setCurrentScreen,data,setData,setIsOpen,setNodeObj}) => {
  const [finalString, setFinalString] = useState(`${data.powerfulVerb}  ${data.keyTransformationalConcept} ${data.ultimateBenefit} ${data.timeFrames}`);
  const {startTime} = useStartEndTime(setData,"WOW");
  const getText = (value: number) => {
     if(value < 40){
        return "It looks like this mission could be refined. What would make it more exciting or meaningful for you?";
     }
     if(value > 40 && value < 70){
        return "Does this feel aligned with your goals? You can refine it for more clarity or move forward."
     }
     return "Great job! This mission looks ready to guide your next steps!";
  }

  const handleSubmit = () => {
    setNodeObj((prev:any) => {
      const endTime = new Date();
      return {
        ...prev,
        objective: finalString,
        objectiveDynamicProperties: {
          ...data,
          timeSpent: [...data.timeSpent,{startTime,endTime,screenName: "RATING"}]
        }
      }
    });
    setIsOpen(false);
  }



  return <div>
      <Title data={data}/>
      {/* <div className='h-8'/> */}
      {/* <Steps steps={STEPS} completedSteps={5}/> */}

      <div className='h-8'/>
      <input className='border border-2 p-2 rounded-md text-center text-gray-400 w-full' type="text" value={finalString} onChange={(e) => setFinalString(e.target.value)}/>
      {/* <PreView value={`To ${data.keyTransformationalConcept} ${data.powerfulVerb} for ${data.ultimateBenefit} ${data.timeFrames}`}/> */}

      <h3 className='text-gray-400 text-center mt-8'>Your mission is nearly ready...</h3>
      <p className='text-gray-400 text-center mt-2'>Let's check how  inspired do you feel?</p>
      <h3 className='text-gray-400 font-bold text-center mt-2'>It makes me want to do:</h3>

      <div className="flex items-center gap-2 text-gray-400 mb-4 mt-2">
          <p className='text-nowrap'>Some Dusting.</p>
          <input type="range" min="1" max="100" value={data.rating} onChange={(e) => setData((prev: Idata) => {return {...prev,rating: Number(e.target.value)}})} className="w-full" id="myRange"/>
          <p className='text-nowrap'>A Victory Roar!</p>
      </div>

      <p className='text-gray-400 text-center'>{getText(data.rating)}</p>

      <NextAndBackButton
          backClick={() => setCurrentScreen(2)}
          nextClick={handleSubmit}
          backText={"Refine Mission"}
      />
  </div>
}

