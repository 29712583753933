import { useEffect, useState } from 'react';
import { Outlet,useLocation } from 'react-router-dom';

import Loader from './common/Loader';
import DefaultLayout from './layout/DefaultLayout';
import { setIsAdminDashboardView } from '../../store/actions';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store/store';

export function AdminDashboard() {
  const [loading, setLoading] = useState<boolean>(true);
  const { pathname } = useLocation();
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);


  useEffect(() => {
    dispatch(setIsAdminDashboardView(pathname.includes("admin-dashboard")))
  }, [pathname,dispatch]);

  return loading ? (
    <Loader />
  ) : (
    <DefaultLayout>
      <Outlet/>
    </DefaultLayout>
  );
}

// source https://tailadmin.com/
