import React, { useEffect, useState } from 'react'
import { getAllUsersAdmin, IUser } from '../../../../store/actions';
import { User } from './User';
import { EditUserModal } from './EditUserModal';

export default function Users() {
    const [isShowUserEditModal, setIsShowUserEditModal] = useState(false);
    const [idOfUserToEdit, setIdOfUserToEdit] = useState("");
    const [users, setUsers] = useState<any>([]);
    const [isFetching, setIsFetching] = useState(false);
    const [error, setError] = useState("");
    
    
    useEffect(() => {
        (async () => {
          setError("");
          setIsFetching(true);
          const res = await getAllUsersAdmin();
          setIsFetching(false);
    
          if(res.success){
            console.log(res);
            
            const tempUsers = res.users?.map(user => {
              const nodeCount = res.nodeCount?.find(n => n.nodeId === user._id);

              return {
                ...user,
                nodeCount: nodeCount?.nodeLength
              }
            })
            setUsers(tempUsers);
          }
          else {
            setError(res.message);
          }
        })()
    
        return () => {
          setUsers([]);
        };
    }, []);

    if(isFetching){
      return <>"Fetching"</>
    }
  
    if(error){
      return <>"Error"</>
    }

    if(isShowUserEditModal){
      return <EditUserModal setIsShowUserEditModal={setIsShowUserEditModal} idOfUserToEdit={idOfUserToEdit} setUsers={setUsers}/>
    }
  
    return (
      <>
      <div className="mx-auto px-4 py-8 sm:px-8">
        <div className="flex items-center justify-between pb-6">
          <div>
            <h2 className="font-semibold text-gray-700">User Accounts</h2>
            <span className="text-xs text-gray-500">View accounts of registered users</span>
          </div>
          <div className="flex items-center justify-between">
            <div className="ml-10 space-x-8 lg:ml-40">
              <button className="flex items-center gap-2 rounded-md bg-blue-600 px-4 py-2 text-sm font-semibold text-white focus:outline-none focus:ring hover:bg-blue-700">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="h-4 w-4">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m0 0l6.75-6.75M12 19.5l-6.75-6.75" />
                </svg>
  
                Button
              </button>
            </div>
          </div>
        </div>
        <div className="overflow-y-hidden rounded-lg border">
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="bg-gray-700 text-left text-xs font-semibold uppercase tracking-widest text-white">
                  <th className="px-5 py-3">Serial No.</th>
                  <th className="px-5 py-3">Full Name</th>
                  <th className="px-5 py-3">Email</th>
                  <th className="px-5 py-3">User Role</th>
                  <th className="px-5 py-3">Created at</th>
                  <th className="px-5 py-3">Total Nodes</th>
                  <th className="px-5 py-3">Last Login</th>
                  <th className="px-5 py-3">Status</th>
                  <th className="px-5 py-3"></th>
                </tr>
              </thead>
              <tbody className="text-gray-500">
                  {
                    users.map((user: IUser,idx:number) => <User setIdOfUserToEdit={setIdOfUserToEdit} user={user} index={idx} setIsShowUserEditModal={setIsShowUserEditModal}/>)
                  }
              </tbody>
            </table>
          </div>
          <div className="flex flex-col items-center border-t bg-white px-5 py-5 sm:flex-row sm:justify-between">
            <span className="text-xs text-gray-600 sm:text-sm"> {users? `Showing 1 to ${users.length} of ${users.length} Entries`:""}</span>
            <div className="mt-2 inline-flex sm:mt-0">
              <button className="mr-2 h-12 w-12 rounded-full border text-sm font-semibold text-gray-600 transition duration-150 hover:bg-gray-100">Prev</button>
              <button className="h-12 w-12 rounded-full border text-sm font-semibold text-gray-600 transition duration-150 hover:bg-gray-100">Next</button>
            </div>
          </div>
        </div>
      </div>
      </>
    )
}
