import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import {Modal} from "./Index";
import {  createSurvey, getSurveyTitle, setIsCreateCognipulseModalOpen } from "../../store/actions";
import { SurveyUI } from "../SurveyUI";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getLabelFromDepth } from "../../utils/getLabelFromDepth";
import { CreatePhySelfie } from "./CreatePhySelfieModal";



export function CreateCognipulseModal() {
  const dispatch:AppDispatch = useDispatch();
  const loggedInUser = useSelector((state:RootState) => state.loggedInUser);
  const cognipulseGoals = useSelector((state:RootState) => state.globalStates.cognipulseGoals);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [surveyTitle, setSurveyTitle] = useState<any>(null);
  const [isPhySelfieModal, setIsPhySelfieModal] = useState<any>(false);
  const [newCognipulseId, setnewCognipulseId] = useState<string | null>(null);

 
  const handleSubmit = async (lastAns:any,lastFactor:any,starttime:any) => {
        setIsSubmiting(true);
        const preparFinalSurveyAnswers = () => {
            const myAnswers = [...answers,{
              starttime,
              endtime: new Date().getTime(),
              rating: lastAns,
              XLFactor: lastFactor
            }];

            let data:any = {};

            myAnswers.forEach((answer:any) => {
              let temp = {
                starttime: answer.starttime,
                endtime: answer.endtime,
                rating: answer.rating,
              };
              data[answer.questiontype || answer.XLFactor] = temp;
            });


            data.isconsentformseen = true;
            data.isbriefingformseen = true;
            data.userIdIn10X = loggedInUser._id;
            data.surveyTitleId = surveyTitle._id;
            data.link = window.location.href;
            data.surveyName = surveyTitle.title;
            data.isNewSurvey = true;
            data.surveyPeriod = null;
            data.activity = cognipulseGoals.categoryOfImprovementGoal?.activity;
            data.dialGoal = cognipulseGoals.currentGoal?._id;
            data.headerGoal = cognipulseGoals.parentGoal?._id;
            data.isCreatedFrom10X = true;

            return data;
        };
      
        const data = preparFinalSurveyAnswers();
  
        if(!cognipulseGoals.categoryOfImprovementGoal?.activity){
          toast.error("Please Add Activity At Category of Improvement Lavel");
          setIsSubmiting(false);
          return;
        }
        
        const res = await createSurvey({...data,email: loggedInUser.email});
        // console.log(res);
        if(res.success){
          setnewCognipulseId(res.survey._id);
          setIsPhySelfieModal(true);
          // dispatch(setIsCreateCognipulseModalOpen(false));
        }
        else {
          toast.error(res.message);
        }
        setIsSubmiting(false);
  }

  useEffect(() => {
    (async() => {
      const res = await getSurveyTitle(false);

      if(res.success){

          
          let shuffled = res.surveyTitle.questions.map((a:any) => ({sort: Math.random(), value: a}))
                                                  .sort((a:any, b:any) => a.sort - b.sort)
                                                  .map((a:any) => a.value);
                                                  
          shuffled = shuffled.map((ques:any) => {
            let goal = `<div style="max-height: 90px;overflow: hidden;margin-top: 8px; box-shadow: 0px 4px 16px rgba(17, 17, 26, 0.1), 0px 8px 24px rgba(17, 17, 26, 0.1), 0px 16px 26px rgba(17, 17, 26, 0.1); border-radius: 4px; padding: 4px;" title="${cognipulseGoals.currentGoal?.objective}">${cognipulseGoals.currentGoal?.objective.split(" ").length > 17? `${cognipulseGoals.currentGoal?.objective.split(" ").filter((item:any,i:number) => i < 17).join(" ")}...`:`${cognipulseGoals.currentGoal?.objective}`}</div>`;
            let question = `${ques.question}`;
            question = question?.replace("study", "Studies");
            question = question?.replace("[insert survey subject]", "");
            question = question?.replace("[insert user input name/title]", "[thisismydialgoal]");
            question = question?.replace("[thisismydialgoal]", goal);
            return {...ques,question};
          })                                             
          setQuestions(shuffled);
          setSurveyTitle(res.surveyTitle);
      }
      else {
        toast.error("Something Went Wrong!");
      }
    })()
  }, [loggedInUser.email,cognipulseGoals.currentGoal]);
  

  if(!surveyTitle){
    return <Modal 
      saveBtnText="Create"
      onSubmit={()=>{}}
      isOpen={true}
      setIsOpen={(bool)=>{dispatch(setIsCreateCognipulseModalOpen(bool))}}
      hideBtns={true}
      onEscape={()=>{dispatch(setIsCreateCognipulseModalOpen(false))}}
    >
      <div className="h-[632px] flex justify-center items-center">Loading...</div>
    </Modal>
  }

  if(isPhySelfieModal){
    return <CreatePhySelfie newCognipulseId={newCognipulseId}/>
  }

  return (
    <Modal
      saveBtnText="Create"
      onSubmit={()=>{}}
      isOpen={true}
      setIsOpen={(bool)=>{dispatch(setIsCreateCognipulseModalOpen(bool))}}
      hideBtns={true}
      onEscape={()=>{dispatch(setIsCreateCognipulseModalOpen(false))}}
    >

      <>
          <p className="mb-3 text-center text-gray-400 font-bold select-none">CogniPulse</p>
          {/* {
            inThePastMinutes && <h6 className="mb-3 text-center text-secondry-color font-bold">In the past {inThePastMinutes} minutes</h6>
          } */}

          <SurveyUI 
            isShowFinish={true} 
            dayOrWeekGoal={"this is day goal"} 
            label={""}  
            handleSubmit={handleSubmit} 
            surveyQuestionInstruction={"Right Now,"} 
            isSubmiting={isSubmiting} 
            questions={questions} 
            answers={answers} 
            setAnswers={setAnswers}
            goalToDisplayInHeader={cognipulseGoals.parentGoal?.objective}
            goalToDisplayInHeaderTitle={getLabelFromDepth(cognipulseGoals.parentGoal?.depth)}
          />
      </>
    </Modal>
  );
}







