import React, { useEffect, useState } from 'react'
import { Modal } from './Index'
import { Button } from '../Buttons/Button';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { Triangle } from '../Triangle';
import { useStartEndTime } from './useStartEndTime';
import { capitalized } from '../../utils';


interface IMissionModal {
  setIsOpen: any;
  setNodeObj: any;
  objectiveDynamicProperties?: any;
}

const screenNames = ['WELCOME',"PROJECT_TITLE","WHERE","WHAT","WHO","WHEN","WOW"] as const;
type TScreenName = typeof screenNames[number];

interface ITimeSpent {
  startTime: Date,
  endTime: Date,
  screenName: TScreenName;
}

interface Idata {
  taskActionVerbs: string;
  specificActivities: string;
  deliverableOutcomes: string;
  rating: number;
  timeSpent: ITimeSpent[];
}

type TypeSetData = React.Dispatch<React.SetStateAction<Idata>>

export const TaskModal: React.FC<IMissionModal> = ({setIsOpen,setNodeObj,objectiveDynamicProperties})  => {
  const [currentScreen, setCurrentScreen] = useState(1);
  const [data, setData] = useState<Idata>({
    taskActionVerbs: "",
    specificActivities: "",
    deliverableOutcomes: "",
    rating: 50,
    timeSpent: []
  });


 
  useEffect(() => {
    if(objectiveDynamicProperties){
      setData({
        taskActionVerbs: objectiveDynamicProperties.taskActionVerbs || "",
        specificActivities: objectiveDynamicProperties.specificActivities || "",
        deliverableOutcomes: objectiveDynamicProperties.deliverableOutcomes || "",
        rating: objectiveDynamicProperties.rating || 50,
        timeSpent: objectiveDynamicProperties.timeSpent || []
      });
    }
  }, [objectiveDynamicProperties]);

  return (
    <>
        <Modal
          saveBtnText="Create"
          onSubmit={()=>{}}
          isOpen={true}
          setIsOpen={setIsOpen}
          hideBtns={true}
          onEscape={() => {setIsOpen(false)}}
        >
          <> 
            {
              currentScreen === 1 && <Welcome data={data} setCurrentScreen={setCurrentScreen} setData={setData}/>
            }
            {
              currentScreen === 2 && <TaskActionVerbs data={data} setData={setData} setCurrentScreen={setCurrentScreen}/>
            }
            {
              currentScreen === 3 && <SpecificActivities data={data} setData={setData} setCurrentScreen={setCurrentScreen}/>
            }
            {
              currentScreen === 4 && <DeliverableOutcomes data={data} setData={setData} setCurrentScreen={setCurrentScreen}/>
            }
            {
              currentScreen === 5 && <Rating setNodeObj={setNodeObj} setIsOpen={setIsOpen} data={data} setData={setData} setCurrentScreen={setCurrentScreen}/>
            }   
          </>
        </Modal>
    </>
  )
}


interface ITitle {
  data: Idata;
}

const Title: React.FC<ITitle> = ({data}) => {
  return <h2 className='text-center font-bold text-xl text-gray-400'>Craft Your Task!</h2>
}


export const ParentNodeObjective = () => {
  const parentNodeObjective = useSelector((state: RootState) => state.globalStates.parentNodeObjective);
  const parentNodeType = useSelector((state: RootState) => state.globalStates.parentNodeType);


  return <>
      <div className="">
        <small className='text-gray-400'>{parentNodeType.split("_").map((n:string) => capitalized(n)).join(" ")}</small>
        <div className="border-2 font-bold p-2">
            <p className='text-gray-400 text-center'>
                {parentNodeObjective || "Create a Key Result First"}
            </p>
        </div>
      </div>
  </>
}


interface IInputField {
  labelText: string;
  smallText: string;
  name: string;
  placeHolder: string;
  id: string;
  value: string;
  onChange: any;
  children?: any;
  setIsHighlighted: any;
}

const InputField: React.FC<IInputField> = ({labelText,smallText,name,placeHolder,id,value,onChange,children,setIsHighlighted}) => {

  const handleFocus = (e:React.FocusEvent<HTMLInputElement>) => {
    e.preventDefault();
    setIsHighlighted(true);
  }


  useEffect(() => {
    document.addEventListener("click",(e: any) => {
      if(e.target.tagName !== "INPUT" && e.target.classList.contains("no-hide") === false){
        setIsHighlighted(false);
      }
    })
    
    return () => {
      document.removeEventListener("click",()=>{});
    };
  }, [setIsHighlighted]);
  

  return <div className='relative'>
            <div className="flex flex-col gap-1">
              <label className='text-gray-400 font-bold' htmlFor="">{labelText}</label>
                <input 
                  onFocus={handleFocus} 
                  // onBlur={handleBlur} 
                  value={value} 
                  onChange={onChange} 
                  type="text" 
                  placeholder={placeHolder} 
                  name={name} 
                  id={id} 
                  className='border p-2 rounded-md'
                />
              <small className='text-gray-400'>{smallText}</small>
            </div>
            {children}
        </div>
}


interface INextAndBackButton {
  nextClick: any;
  backClick: any;
  backText?: any;
  nextText?: any;
}

const NextAndBackButton: React.FC<INextAndBackButton> = ({nextClick,backClick,backText,nextText}) => {


  return <>
      <div className="flex gap-4">
          <Button onClick={backClick} style={{width: "100%",marginTop: "40px",background: "#0000ff"}}>{backText || "Back"}</Button>
          <Button onClick={nextClick} style={{width: "100%",marginTop: "40px"}}>{nextText || "Next"}</Button>
      </div>
  </>
}


interface IDropDown {
  list: string[],
  value: string;
  setData: any;
  isShow: boolean;
  readOnlyList?: string[];
}

const DropDown: React.FC<IDropDown> = ({list,value,setData,isShow,readOnlyList=[]}) => {
  list = list.filter(item => item.toLowerCase().includes(value.toLowerCase()));
 
  
  if(!isShow){
    return <></>;
  }

  const handleClick = (e:React.MouseEvent<HTMLDivElement>,item:string) => {
    if(readOnlyList.includes(item)){
      return;
    }
    setData(item);
  }
  
  return <div className='absolute top-[72px] left-0 border bg-white w-full rounded-md z-[10]'>
            <div className="w-full h-full flex flex-col max-h-[150px] overflow-auto py-2 text-gray-400">
                 {
                    list.map((item,idx) => <div onClick={(e) => handleClick(e,item)} key={idx} className={`${readOnlyList.includes(item)? "bg-gray-400 text-white no-hide":"hover:bg-gray-100 cursor-pointer"}  px-4 py-1 flex items-center`}>{item}</div>)
                 }
            </div>
        </div>
}


interface IPreView {
  value: string;
}

const PreView: React.FC<IPreView> = ({value}) => {


  return <>
      <div className='min-h-[44px] border border-2 p-2  text-center text-gray-400 '>
        {value}
      </div>
  </>
}


interface IWelcome {
  setCurrentScreen: any;
  setData: TypeSetData;
  data: Idata;
}


const Welcome: React.FC<IWelcome> = ({setCurrentScreen,setData,data}) => {
  useStartEndTime(setData,"WEOCOME");



  return <>
      <Title data={data}/>

      <p className='text-gray-400 text-center'>
          Your Key Result is your focus.
      </p>
      
      <div className="">
          <ParentNodeObjective/>

          <Triangle topAngle={50} text='Actionable,Measurable Result that drives Progress'/>

          <div className="border flex flex-col items-center py-2">
            <h1 className='text-3xl font-bold  text-gray-400'>Task</h1>
            <small className=' text-gray-600'>e.g Streamline Product Development Process</small>
          </div>
      </div>

      <p className='text-gray-400'>
          This process is about crafting a clear,
          impactful, actionable task that directly
          contributes to achieving your Key Result.
      </p>

      <Button onClick={() => setCurrentScreen(2)} style={{width: "100%",marginTop: "40px"}}>Next</Button>
  </>
}


interface IChange {
  setCurrentScreen: any;
  data: Idata;
  setData: any;
}

const TaskActionVerbs: React.FC<IChange> = ({setCurrentScreen,data,setData}) => {
  const [readOnlyList] = useState([
    "Strategic Actions",
    "Creative Actions",
    "Analytical Actions",
    "Execution-Oriented Actions",
    "Communication Actions",
    "Collaboration-Focused Actions",
    "Marketing and Sales Actions",
    "Learning and Development Actions",
    "Monitoring and Improvement Actions",
    "Administrative and Process Actions",
    "Problem-Solving Actions"
  ])
  const [list] = useState([
       "Design", "Build", "Execute", "Develop", "Optimise", "Create",
        "Lead", "Launch", "Test", "Scale"
  ]);
  const [isHighlighted, setIsHighlighted] = useState(false);
  useStartEndTime(setData,"TaskActionVerbs".toUpperCase());

  return <>

      <div>
          <p className='text-gray-400 text-center'>What action will you take?</p>
          <ParentNodeObjective/>
          <Triangle text='big impact on' topAngle={50}/>
          <PreView value={data.taskActionVerbs}/>
          <Triangle text='Action' topAngle={50}/>
          <InputField
              labelText=''
              smallText=""
              name='taskActionVerbs'
              placeHolder='Enter or Select the quantiable target'
              id='taskActionVerbs'
              value={data.taskActionVerbs}
              onChange={(e: any) => setData((prev:Idata) => {return {...prev,taskActionVerbs: e.target.value}})}
              setIsHighlighted={setIsHighlighted}
          >
            <DropDown 
              list={list} 
              readOnlyList={readOnlyList}
              value={data.taskActionVerbs}
              setData={(str: string) => setData((prev:Idata) => {return {...prev,taskActionVerbs: str}})}
              isShow={isHighlighted}
            />
          </InputField>  
      </div>

      <NextAndBackButton
          backClick={() => setCurrentScreen(1)}
          nextClick={() => setCurrentScreen(3)}
      />
  </>
}


interface IImpact {
  setCurrentScreen: any;
  data: Idata;
  setData: any;
}

const SpecificActivities: React.FC<IImpact> = ({setCurrentScreen,data,setData}) => {
  const [readOnlyList] = useState([
    "Design & Development Activities",
    "Documentation & Writing Activities",
    "Analysis & Research Activities",
    "Presentation & Communication Activities",
    "Creative & Content Activities",
    "Process & Workflow Activities",
    "Marketing & Sales Activities",
    "Product & Technology Activities",
    "Learning & Development Activities",
    "Operations & Administration Activities",
    "Metrics & Performance Activities"
  ])
  const [list] = useState([
    "Marketing Campaign", "Sales Funnel", "Product Prototype", "Team Onboarding",
    "Financial Plan", "Customer Survey", "Partnership Agreement",
    "Website Optimisation", "Social Media Strategy", "Investor Pitch"
  ]);
  const [isHighlighted, setIsHighlighted] = useState(false);
  useStartEndTime(setData,"SpecificActivities".toUpperCase());

  return <>
      <div>
          <p className='text-gray-400 text-center'>What specific activity will you focus on?</p>
          <ParentNodeObjective/>
          <Triangle text='big impact on' topAngle={50}/>
          <PreView value={`${data.taskActionVerbs} ${data.specificActivities}`}/>
          <Triangle text='Specific Activity' topAngle={60}/>
          <InputField
              labelText=''
              smallText=""
              name='specificActivities'
              placeHolder='What metric you want to take'
              id='specificActivities'
              value={data.specificActivities}
              onChange={(e: any) => setData((prev:Idata) => {return {...prev,specificActivities: e.target.value}})}
              setIsHighlighted={setIsHighlighted}
          >
            <DropDown 
              list={list} 
              readOnlyList={readOnlyList}
              value={data.specificActivities}
              setData={(str: string) => setData((prev:Idata) => {return {...prev,specificActivities: str}})}
              isShow={isHighlighted}
            />
          </InputField> 
      </div>

      <NextAndBackButton
          backClick={() => setCurrentScreen(2)}
          nextClick={() => setCurrentScreen(4)}
      />
  </>
}



interface ITime {
  setCurrentScreen: any;
  data: Idata;
  setData: any;
}

const DeliverableOutcomes: React.FC<ITime> = ({setCurrentScreen,data,setData}) => {
  const [readOnlyList] = useState([
    "Product & Feature Outcomes",
    "User-Centric Outcomes",
    "Stakeholder & Collaboration Outcomes",
    "Marketing & Sales Outcomes",
    "Analysis & Research Outcomes",
    "Performance & Reporting Outcomes",
    "Learning & Development Outcomes",
    "Project Management & Planning Outcomes",
    "Operations & Administrative Outcomes",
    "Impact & Value Outcomes"
  ])
  const [list] = useState([
      "Customer Acquisition", "Revenue Growth", "Market Share", "Lead Generation",
      "Brand Awareness", "Team Alignment", "Operational Efficiency",
      "Product Delivery", "Cost Savings", "User Engagement"
  ]);
  const [isHighlighted, setIsHighlighted] = useState(false);
  useStartEndTime(setData,"DeliverableOutcomes".toLowerCase());

  return <>
      <div className="">
          <p className='text-gray-400 text-center'>What will be the deliverable or outcome of this activity?</p>

          <ParentNodeObjective/>
          
          <Triangle text='big impact on' topAngle={50}/>

          <PreView value={`${data.taskActionVerbs} ${data.specificActivities} ${data.deliverableOutcomes}`}/>

          <Triangle text='End result?' topAngle={75}/>

          <InputField
              labelText='What does the end result look like?'
              smallText=""
              name='deliverableOutcomes'
              placeHolder='Enter deliverable or outcome'
              id='deliverableOutcomes'
              value={data.deliverableOutcomes}
              onChange={(e: any) => setData((prev:Idata) => {return {...prev,deliverableOutcomes: e.target.value}})}
              setIsHighlighted={setIsHighlighted}
            >
              <DropDown 
                list={list} 
                readOnlyList={readOnlyList}
                value={data.deliverableOutcomes}
                setData={(str: string) => setData((prev:Idata) => {return {...prev,deliverableOutcomes: str}})}
                isShow={isHighlighted}
              />
          </InputField>
      </div>


      <NextAndBackButton
          backClick={() => setCurrentScreen(3)}
          nextClick={() => setCurrentScreen(5)}
      />
  </>
}


interface IRating   {
  setCurrentScreen: any;
  data: Idata;
  setData: any;
  setIsOpen: any;
  setNodeObj: any;
}

const Rating: React.FC<IRating> = ({setCurrentScreen,data,setData,setIsOpen,setNodeObj}) => {
  const [finalString, setFinalString] = useState(`${data.taskActionVerbs} ${data.specificActivities} ${data.deliverableOutcomes}`);
  const {startTime} = useStartEndTime(setData,"RATING");
  const getText = (value: number) => {
     if(value < 40){
        return "It looks like this Task could be refined. What would make it more exciting or meaningful for you?";
     }
     if(value > 40 && value < 70){
        return "Does this feel aligned with your goals? You can refine it for more clarity or move forward."
     }
     return "Great job! This task looks ready to guide your next steps!";
  }

  const handleSubmit = () => {
    setNodeObj((prev:any) => {
      const endTime = new Date();
      return {
        ...prev,
        objective: finalString,
        objectiveDynamicProperties: {
          ...data,
          timeSpent: [...data.timeSpent,{startTime,endTime,screenName: "RATING"}]
        }
      }
    });
    setIsOpen(false);
  }



  return <div>
      <div className="">
          <ParentNodeObjective/>
          <Triangle text='big impact on' topAngle={50}/>
          {/* <Steps steps={STEPS} completedSteps={5}/> */}
          <input className='border border-2 p-2 text-center text-gray-400 w-full font-bold' type="text" value={finalString} onChange={(e) => setFinalString(e.target.value)}/>
          {/* <PreView value={`To ${data.what} ${data.where} for ${data.deliverableOutcomes} ${data.when}`}/> */}
      </div>
      
      <h3 className='text-gray-400 text-center mt-8'>You Task is nearly ready...</h3>
      <p className='text-gray-400 text-center mt-2'>Let's check how  inspired do you feel?</p>
      <h3 className='text-gray-400 font-bold text-center mt-2'>It makes me want to do:</h3>

      <div className="flex items-center gap-2 text-gray-400 mb-4 mt-2">
          <p className='text-nowrap'>Some Dusting.</p>
          <input type="range" min="1" max="100" value={data.rating} onChange={(e) => setData((prev: Idata) => {return {...prev,rating: Number(e.target.value)}})} className="w-full" id="myRange"/>
          <p className='text-nowrap'>A Victory Roar!</p>
      </div>

      <p className='text-gray-400 text-center'>{getText(data.rating)}</p>

      <NextAndBackButton
          backClick={() => setCurrentScreen(2)}
          nextClick={handleSubmit}
          backText={"Refine Objective"}
      />
  </div>
}





