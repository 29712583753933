import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';

export const CursorText: React.FC = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const copiedOrCurNodeObjective = useSelector((state: RootState) => state.globalStates.copiedOrCurNodeObjective);
  
  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      setPosition({
        x: event.clientX,
        y: event.clientY,
      });
    };

    window.addEventListener('mousemove', handleMouseMove);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <div
      style={{
        position: 'fixed',
        top: position.y + 10, // Offset to avoid overlapping the cursor
        left: position.x + 10,
        pointerEvents: 'none', // Ensure it's not clickable
        color: 'black',
        // fontSize: '16px',
        // fontWeight: 'bold',
        zIndex: 2000, // Keep it above other elements
      }}
    >
      {copiedOrCurNodeObjective}
    </div>
  );
};
