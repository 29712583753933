import { useEffect, useRef, useState } from "react";

export const useStartEndTime = (setData: any,screenName: string) => {
  const [startTime, setStartTime] = useState<Date | null>(null);
  const endTimeRef = useRef<Date | null>(null);

  // Function to retrieve the end time
  const getEndTime = (): Date | null => {
    return endTimeRef.current;
  };

  useEffect(() => {
    // Set the start time when the component mounts
    setStartTime(new Date());

    return () => {
      // Record the end time when the component unmounts
      endTimeRef.current = new Date();
    };
  }, []);


  useEffect(() => {
    return () => {
      const endTime = getEndTime();
      if(startTime && endTime){
        setData((prev:any) => {
          prev.timeSpent.push({startTime,endTime,screenName});
          return prev;
        })
      }
    };
  }, [startTime,screenName,setData]);

  
  return { startTime, getEndTime };
};