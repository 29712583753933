import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import {Modal} from "./Index";
import {  createSurvey, getSurveyTitle, setIsCreateImpulseModalOpen } from "../../store/actions";
import { SurveyUI } from "../SurveyUI";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getLabelFromDepth } from "../../utils/getLabelFromDepth";
import { Button } from "../Button";
import { ImpulseRating } from "../ImpulseRating";



export function CreateImpulseModal() {
  const dispatch:AppDispatch = useDispatch();
  const loggedInUser = useSelector((state:RootState) => state.loggedInUser);
  const cognipulseGoals = useSelector((state:RootState) => state.globalStates.cognipulseGoals);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [surveyTitle, setSurveyTitle] = useState<any>(null);
  const [minute, setMinute] = useState(0);
  const [pastTimeCompleted, setPastTimeCompleted] = useState(false);
  const [isShowImpulseRating, setIsShowImpulseRating] = useState(false);
  const [impactSurveyId, setImpactSurveyId] = useState("");
 
  const handleSubmit = async (lastAns:any,lastFactor:any,starttime:any) => {
        setIsSubmiting(true);
        const preparFinalSurveyAnswers = () => {
            const myAnswers = [...answers,{
              starttime,
              endtime: new Date().getTime(),
              rating: lastAns,
              XLFactor: lastFactor
            }];

            let data:any = {};

            myAnswers.forEach((answer:any) => {
              let temp = {
                starttime: answer.starttime,
                endtime: answer.endtime,
                rating: answer.rating,
              };
              data[answer.questiontype || answer.XLFactor] = temp;
            });


            data.isconsentformseen = true;
            data.isbriefingformseen = true;
            data.userIdIn10X = loggedInUser._id;
            data.surveyTitleId = surveyTitle._id;
            data.link = window.location.href;
            data.surveyName = surveyTitle.title;
            data.isNewSurvey = true;
            data.surveyPeriod = minute;
            data.activity = cognipulseGoals.categoryOfImprovementGoal?.activity;
            data.dialGoal = cognipulseGoals.currentGoal?._id;
            data.headerGoal = cognipulseGoals.parentGoal?._id;
            data.isCreatedFrom10X = true;

            return data;
        };
      
        const data = preparFinalSurveyAnswers();
  
        if(!cognipulseGoals.categoryOfImprovementGoal?.activity){
          toast.error("Please Add Activity At Category of Improvement Lavel");
          setIsSubmiting(false);
          return;
        }
        
        const res = await createSurvey({...data,email: loggedInUser.email});
        // console.log(res);
        if(res.success){
          setIsShowImpulseRating(true);
          setImpactSurveyId(res.survey._id);
        }
        else {
          toast.error(res.message);
        }
        setIsSubmiting(false);
  }

  useEffect(() => {
    (async() => {
      const res = await getSurveyTitle(true);

      if(res.success){
          
          let shuffled = res.surveyTitle.questions.map((a:any) => ({sort: Math.random(), value: a}))
                                                  .sort((a:any, b:any) => a.sort - b.sort)
                                                  .map((a:any) => a.value);
                                                  
          shuffled = shuffled.map((ques:any) => {
            let goal = `<div style="max-height: 90px;overflow: hidden;margin-top: 8px; box-shadow: 0px 4px 16px rgba(17, 17, 26, 0.1), 0px 8px 24px rgba(17, 17, 26, 0.1), 0px 16px 26px rgba(17, 17, 26, 0.1); border-radius: 4px; padding: 4px;" title="${cognipulseGoals.currentGoal?.objective}">${cognipulseGoals.currentGoal?.objective.split(" ").length > 17? `${cognipulseGoals.currentGoal?.objective.split(" ").filter((item:any,i:number) => i < 17).join(" ")}...`:`${cognipulseGoals.currentGoal?.objective}`}</div>`;
            let question = `${ques.question}`;
            question = question?.replace("study", "Studies");
            question = question?.replace("[insert survey subject]", "");
            question = question?.replace("[insert user input name/title]", "[thisismydialgoal]");
            question = question?.replace("[thisismydialgoal]", goal);
            return {...ques,question};
          })                                             
          setQuestions(shuffled);
          setSurveyTitle(res.surveyTitle);
      }
      else {
        toast.error("Something Went Wrong!");
      }
    })()
  }, [loggedInUser.email,cognipulseGoals.currentGoal]);
  

  if(!surveyTitle){
    return <Modal 
      saveBtnText="Create"
      onSubmit={()=>{}}
      isOpen={true}
      setIsOpen={(bool)=>{dispatch(setIsCreateImpulseModalOpen(bool))}}
      hideBtns={true}
      onEscape={()=>{dispatch(setIsCreateImpulseModalOpen(false))}}
    >
      <div className="h-[632px] flex justify-center items-center">Loading...</div>
    </Modal>
  }

  if(!pastTimeCompleted){
    return <Modal
              saveBtnText="Create"
              onSubmit={()=>{}}
              isOpen={true}
              setIsOpen={(bool)=>{dispatch(setIsCreateImpulseModalOpen(bool))}}
              hideBtns={true}
              onEscape={()=>{dispatch(setIsCreateImpulseModalOpen(false))}}
            >
                <NonCogniPulseTime 
                  inThePastMinutes={minute} 
                  setInThePastMinutes={setMinute}
                  setPastTimeCompleted={setPastTimeCompleted}
                />
            </Modal>
  }


  if(isShowImpulseRating){
    return  <Modal
              saveBtnText="Create"
              onSubmit={()=>{}}
              isOpen={true}
              setIsOpen={(bool)=>{dispatch(setIsCreateImpulseModalOpen(bool))}}
              hideBtns={true}
              onEscape={()=>{dispatch(setIsCreateImpulseModalOpen(false))}}
            >
                <ImpulseRating 
                  inThePastMinutes={minute}
                  impactSurveyId={impactSurveyId}
                />
            </Modal>
  }


  return (
    <Modal
      saveBtnText="Create"
      onSubmit={()=>{}}
      isOpen={true}
      setIsOpen={(bool)=>{dispatch(setIsCreateImpulseModalOpen(bool))}}
      hideBtns={true}
      onEscape={()=>{dispatch(setIsCreateImpulseModalOpen(false))}}
    >

      <div>
          <p className="mb-3 text-center text-gray-400 font-bold select-none">Impulse</p>
          {
            minute && <h6 className="mb-3 text-center text-gray-400 font-bold">In the past {minute} minutes</h6>
          }

          <SurveyUI 
            isShowFinish={true} 
            dayOrWeekGoal={"this is day goal"} 
            label={""}  
            handleSubmit={handleSubmit} 
            surveyQuestionInstruction={"Right Now,"} 
            isSubmiting={isSubmiting} 
            questions={questions} 
            answers={answers} 
            setAnswers={setAnswers}
            goalToDisplayInHeader={cognipulseGoals.parentGoal?.objective}
            goalToDisplayInHeaderTitle={getLabelFromDepth(cognipulseGoals.parentGoal?.depth)}
          />
      </div>
    </Modal>
  );
}




interface INonCogniPulseTime {
  setPastTimeCompleted: any;
  inThePastMinutes: any;
  setInThePastMinutes: any;
}

export const NonCogniPulseTime: React.FC<INonCogniPulseTime> = ({setPastTimeCompleted,inThePastMinutes,setInThePastMinutes}) => {
  const cognipulseGoals = useSelector((state:RootState) => state.globalStates.cognipulseGoals);
  const [value, setValue] = useState(null);
  const array = [
    {
      text: "5 Minutes",
      minute: 5
    },
    {
      text: "10 Minutes",
      minute: 10
    },
    {
      text: "20 Minutes",
      minute: 20
    },
    {
      text: "30 Minutes",
      minute: 30
    },
    {
      text: "40 Minutes",
      minute: 40
    },
    {
      text: "50 Minutes",
      minute: 50
    },
    {
      text: "1 hour",
      minute: 60
    },
  ];
  

  const [times] = useState(array);


  const handleChange = (e:any) => {
    setValue(e.target.value);
    setInThePastMinutes(Number(e.target.value));
  }

 

  return (
    <>

      <h1 className="pt-10 md:text-center text-[24px] md:text-[30px] font-bold text-gray-600">Capture your creative and productive impact on <br/> <span className="bg-secondry-color/20 text-secondry-color text-sm px-4 py-1 rounded-md font-normal">{cognipulseGoals.currentGoal?.objective}</span> </h1>

      <h3 className="md:text-center pt-2 mb-4 mt-10 text-gray-400 text-[18px]">Select the time you were working on this activity (up to this moment)</h3>

      <div className="max-w-3xl mx-auto flex flex-wrap gap-2 md:justify-center">
        {
          times.map((item,idx) => (
            <button onClick={()=>{setInThePastMinutes(item.minute);setValue(null)}} className={`border border-gray-200 shadow-md rounded p-2 cursor-pointer ${(inThePastMinutes === item.minute && !value)? "bg-gray-600 text-white font-bold":""}`} title="click to select/deselect" key={idx}>{item.text}</button>
          ))
        }
      </div>
      <div className="flex flex-col w-full md:w-[300px] mx-auto mt-8">
          <label htmlFor="inThePastMinutes" className="text-secondry-color font-bold text-center">Enter Custom Time (in Minutes)</label>
          <input 
            value={value === null?  "":value} 
            onChange={handleChange} 
            id="inThePastMinutes" 
            placeholder="Enter time in minutes, e.g., 25mins"  
            type="number" 
            className="p-2 rounded-md bg-gray-200"
          />
      </div>

      <div className="flex justify-center mt-14 gap-5 w-[500px] max-w-full mx-auto">
          <Button 
            style={{width: "100%"}}
            onClick={()=>{setPastTimeCompleted(true)}}
            label="Next"
            isDisabled={inThePastMinutes? false:true}
          />
      </div>
    </>
  )
}






