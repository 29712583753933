import { fetchRequest } from "../../utils";

const root = window.location.hostname === "localhost"? "http://localhost:4000":"https://tenxsurveycasestudy.herokuapp.com";
// const root = "https://tenxsurveycasestudy.herokuapp.com";


interface Ibody {
    email: string;
}
    
export const createSurvey = async (body:Ibody) =>  {
        try {
            const res: any = await fetchRequest(`${root}/api/v1/10x/surveys`, "POST", {...body});
            
            return res;

        } catch (error) {
            console.error(error);
        }
}

export const getSurveyTitle = async (isImpulse:boolean)  => {
        try {
            const res: any = await fetchRequest(`${root}/api/v1/10x/survey-titles?isImpulse=${isImpulse}`, "GET", {});
            console.log(res);
            return res;

        } catch (error) {
            console.log(error);
            console.error(error);
        }
}

export const getActivityOfUser = async (label: string | null)  => {
    try {

        let res = undefined;

        if(label){
            res = await fetchRequest(`${root}/api/v1/10x/activities?label=${label}`, "GET", {});
        }
        else {
            res = await fetchRequest(`${root}/api/v1/10x/activities`, "GET", {});
        }
        
        return res;

    } catch (error) {
        console.log(error);
        console.error(error);
    }
}

export const createGoalProgress = async (data:any)  => {
    try {
        const res: any = await fetchRequest(`${root}/api/v1/10x/goal-progress`, "POST", {...data});
        // console.log(res);
        return res;

    } catch (error) {
        console.log(error);
        console.error(error);
    }
}

export const createEventForXLRate = async (data:any)  => {
    try {
        const res: any = await fetchRequest(`${root}/api/v1/10x/events`, "POST", {...data});
        // console.log(res);
        return res;

    } catch (error) {
        console.log(error);
        console.error(error);
    }
}

export const getAllTagsOfUser = async ()  => {
    try {
        const res: any = await fetchRequest(`${root}/api/v1/10x/tags`, "GET", {});
        // console.log(res);
        return res;

    } catch (error) {
        console.log(error);
        console.error(error);
    }
}

export const createTag = async (data:any)  => {
    try {
        const res: any = await fetchRequest(`${root}/api/v1/10x/tags`, "POST", {...data});
        // console.log(res);
        return res;

    } catch (error) {
        console.log(error);
        console.error(error);
    }
}

export const deleteTag = async (tagId:string)  => {
    try {
        const res: any = await fetchRequest(`${root}/api/v1/10x/tags/${tagId}`, "DELETE", {});
        // console.log(res);
        return res;

    } catch (error) {
        console.log(error);
        console.error(error);
    }
}
