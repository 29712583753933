import { 
    DOMAIN_NAME,
    LOGIN_USER
} from "../../constants";
import {
    fetchRequest,
    setToken
} from "../../utils";
import { toast } from "react-toastify";
import { getAllEventsOfLoggedInUser } from "./event";
import store, { RootState } from "../store";


// Define the types for user and action
interface User {
    id: string; // or any other identifier type
    name?: string; // add more user properties as needed
    email?: string; // example property
    // Include other properties based on your user structure
}

interface LoginUserAction {
    type: typeof LOGIN_USER;
    payload: User;
}

// Define the dispatch type for TypeScript
type DispatchType = (action: LoginUserAction) => void;

export const getLoggedInUser = () => async (dispatch: any) => {
    try {
        const res = await fetchRequest(`${DOMAIN_NAME}/users/loggedIn`, "GET", {});
        
        // console.log(res);
        
        if (res.success) {
            dispatch(getAllEventsOfLoggedInUser());
            dispatch({ type: LOGIN_USER, payload: res.user }); // Assuming res.user matches User interface
            toast.success("Login Success!");
        } else {
            toast.error(res.message);
        }
    } catch (error:any) {
        // console.error(error); // Log error for debugging
        toast.error(error.message); // Optional: Notify user of the error
    }
}

export const createUser = (user: any) => async (dispatch: DispatchType) => {
    try {
        const res = await fetchRequest(`${DOMAIN_NAME}/users/signup`, "POST", user);
        
        if (res.success) {
            toast.success("Account Created And Login Success!");
            setToken(res.token);
            dispatch({ type: LOGIN_USER, payload: res.user }); // Assuming res.user matches User interface
            // You can uncomment the following lines if needed
            // dispatch(setAddNodeModalOpen(true));
            // dispatch(setParentIdOfNodeTobeCreated(null));
        } else {
            toast.error(res.message);
        }
    } catch (error) {
        console.error(error); // Log error for debugging
        toast.error("Failed to create account."); // Optional: Notify user of the error
    }
}

export const updateFireBaseTokens = async (token: string) => {
    try {
        const state: RootState = store.getState();
        const loggedInUser = state.loggedInUser;
        if(loggedInUser){
            const firebaseNotificationTokens = [...new Set([...loggedInUser.firebaseNotificationTokens,token])];
            const res = await fetchRequest(`${DOMAIN_NAME}/users/my/detail`, "PUT", {firebaseNotificationTokens});
            return res;
        }
        else {
            return null;
        }
        
    } catch (error) {
        console.error(error); // Log error for debugging
        // toast.error("Failed to create account."); // Optional: Notify user of the error
    }
}

export const getUserById = async (id: string): Promise<any> => {
    try {
        const res = await fetchRequest(`${DOMAIN_NAME}/users/${id}`, "GET", {});
        return res;
    } catch (error) {
        console.error(error); // Log error for debugging
        return null; // Optional: Return null or handle error as needed
    }
}
