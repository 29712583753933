import { 
    SET_EVENTS,
    ADD_EVENT,
    UPDATE_EVENT,
    DELETE_EVENT,
    RESET_EVENTS
} from "../../constants";

interface Event {
    _id: string;
    // Add other properties of Event here
}

interface TreeAction {
    type: string;
    payload: any;
}

const tree = (state: Event[] | null = null, action: TreeAction): Event[] | null => {
    switch (action.type) {
        case RESET_EVENTS:
              return null;
        case SET_EVENTS:
            return action.payload;
        case ADD_EVENT:
            return state ? [...state, action.payload] : [action.payload];
        case UPDATE_EVENT:
            return state ? state.map(ev => ev._id !== action.payload._id ? { ...ev } : { ...ev, ...action.payload }) : state;
        case DELETE_EVENT:
            return state ? state.filter(ev => ev._id !== action.payload.eventId) : state;
        default:
            return state;
    }
}

export default tree;
