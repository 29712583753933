import store from "../store/store";
import {
    setCurrentTask,
    setCurrentTasksParents, 
    setUpcomingTask, 
    setUpcomingTasksParents
} from "../store/actions";
import { getCurrentTask } from "./index";
import moment from "moment";

interface Event {
    startTime: string;
    endTime: string;
    node: {
        _id: string;
    };
}

interface TaskNode {
    _id: string;
    isSomeOneWorkingOnThis?: boolean;
    isCompleted?: boolean;
    isDefered?: boolean;
    children?: TaskNode[];
    data: TaskNode;
    depth?: number;
    parent?: TaskNode;
    descendants: () => TaskNode[];
}

let currentTask: TaskNode | undefined = undefined;
let parentObjectiveNode: TaskNode[] = [];
let timeOuts: any[] = [];

function calc(root: any, events: Event[],isCompletedNodesVisible?:any): void {
    // focus blocks related 
    const state = store.getState();
    currentTask = undefined;
    parentObjectiveNode = [];

    const upCommingEvents = events?.filter(ev => transformToCurrentWeek(new Date(ev.startTime)) > new Date());

    
    oldCode(root);

    if(state.globalStates.currentTask?.startTime){
        findUpcommingTask(events,root,state.globalStates.currentTask);
        return;
    }

    if (state.globalStates.isPaused) {
        return;
    }

    const inCompleteTask = root.descendants().find((d:any) => d.data.isSomeOneWorkingOnThis);


    if (inCompleteTask) {
        loadIncompleteTask(inCompleteTask, state);

        const todayEvent = getTCurrentEvent(events);
        const categoryOfChange:any = root.descendants().find((d:any) => d.data._id === todayEvent?.node._id);
        const undeferedTask: any = categoryOfChange?.data.children?.find((c:any) => !c.isCompleted && !c.isDefered);

        if (undeferedTask) {
            const result:any = getCurrentTask(undeferedTask, [categoryOfChange.data]);
            currentTask = result.currentTask;
            parentObjectiveNode = result.parentObjectives;

            if (inCompleteTask._id !== currentTask?._id) {
                store.dispatch(setUpcomingTask(currentTask));
                store.dispatch(setUpcomingTasksParents(parentObjectiveNode));
            }
        }

    } 
    else {
        const msnd = state.globalStates.manuallyScheduleNodeId;
        const id = msnd ? msnd[msnd.length - 1] : undefined;
        const manuallyScheduledNode: any = root.descendants().find((d:any) => !d.data.isDefered && !d.data.isCompleted && d.data._id === id);

        
        if (!manuallyScheduledNode) {
            store.dispatch(setCurrentTask(null));
            const todayEvent = getTCurrentEvent(events);
            const categoryOfChange:any = root.descendants().find((d:any) => d.data._id === todayEvent?.node._id);
            const undeferedTask:any = categoryOfChange?.data.children?.find((c:any) => !c.isCompleted && !c.isDefered);

            if (undeferedTask) {
                const result:any = getCurrentTask(undeferedTask, [categoryOfChange.data]);
                currentTask = result.currentTask;
                parentObjectiveNode = result.parentObjectives;
            }

            store.dispatch(setCurrentTask(currentTask));
            store.dispatch(setCurrentTasksParents(parentObjectiveNode));
        } 
        else {
            let task = manuallyScheduledNode;
            let parentAndObjectives: any = [];
            while (task.depth !== 1 || !task.parent) {
                if (manuallyScheduledNode.data._id !== task.data._id) {
                    parentAndObjectives.unshift(task.data);
                }
                task = task.parent!;
            }
            const result = getCurrentTask(manuallyScheduledNode.data, [...parentAndObjectives]);
            store.dispatch(setCurrentTask(result.currentTask));
            store.dispatch(setCurrentTasksParents(result.parentObjectives));
        }
    }


    timeOuts.forEach((timeOut) => clearTimeout(timeOut));
    upCommingEvents?.forEach(upCommingEvent => {
        const timeOut = setTimeout(() => {
            const categoryOfChange:any = root.descendants().find((d:any) => d.data._id === upCommingEvent?.node._id);
            const undeferedTask:any = categoryOfChange?.data.children?.find((c:any) => !c.isCompleted && !c.isDefered);

            if (undeferedTask) {
                const result:any = getCurrentTask(undeferedTask, [categoryOfChange.data]);
                currentTask = result.currentTask;
                parentObjectiveNode = result.parentObjectives;
                const ct = state.globalStates.currentTask;

                if(!ct){
                    store.dispatch(setCurrentTask(result.currentTask));
                    store.dispatch(setCurrentTasksParents(result.parentObjectives));
                }
                else {
                    store.dispatch(setUpcomingTask(currentTask));
                    store.dispatch(setUpcomingTasksParents(parentObjectiveNode));
                }
            }
        }, moment(transformToCurrentWeek(new Date(upCommingEvent.startTime))).diff(moment(),"millisecond"));

        timeOuts.push(timeOut);
    })
}

export default calc;

function loadIncompleteTask(inCompleteTask: TaskNode, state: any): void {
    let task = inCompleteTask;
    let parentAndObjectives: TaskNode[] = [];
    while (task.depth !== 1 || !task.parent) {
        if (inCompleteTask.data._id !== task.data._id) {
            parentAndObjectives.unshift(task.data);
        }
        task = task.parent!;
    }
    const currentTask = inCompleteTask.data;
    const parentObjectiveNode = parentAndObjectives;

    store.dispatch(setCurrentTask(currentTask));
    store.dispatch(setCurrentTasksParents(parentObjectiveNode));
    if (currentTask && state.globalStates.currentTask && state.globalStates.currentTask._id !== currentTask._id && state.globalStates.currentTasksParents[0]._id !== parentObjectiveNode[0]._id) {
        store.dispatch(setUpcomingTask(currentTask));
        store.dispatch(setUpcomingTasksParents(parentObjectiveNode));
    }
}

function getTCurrentEvent(events: Event[]): Event | null {
    if (!events) {
        return null;
    }

    return events?.find(ev => {
        const startTime = transformToCurrentWeek(new Date(ev.startTime));
        const endTime = transformToCurrentWeek(new Date(ev.endTime));
        return new Date() >= startTime && new Date() <= endTime;
    }) || null;
}

export function transformToCurrentWeek(dateInISOFormat: Date): Date {
    const currentDate = new Date();
    dateInISOFormat = new Date(dateInISOFormat);
    const dayOfTheWeek = getDayOfWeek(currentDate, dateInISOFormat.getDay());

    dayOfTheWeek.setHours(dateInISOFormat.getHours());
    dayOfTheWeek.setMinutes(dateInISOFormat.getMinutes());

    return dayOfTheWeek;

    function getDayOfWeek(date: Date, targetDay: number): Date {
        const dayOfWeek = date.getDay(); // 0 (Sunday) to 6 (Saturday)
        const diff = date.getDate() - dayOfWeek + targetDay; // Calculate the difference to get to the target day
        return new Date(date.setDate(diff));
    }
}


function oldCode(root:any){
    deadlineInheritance(root);
    claculateNetRemaningMinutesTilDeadline(root);
    calculateNoOfFocusBlocks(root);
    numberOfFocusBlocksTillDeadline(root);
    relativeEstDurationValueNodeAsScale10to0(root);
    nodeProductivityValue(root);
    productivityPoints(root);
   

    function deadlineInheritance(root:any){

        visit(root,null);
        function visit(d:any,parentDeadline:any){

            if(!d.data.deadline && parentDeadline){
                d.data.tempDeadline = parentDeadline;
            }

            if(d.data.deadline && parentDeadline && new Date(d.data.deadline) < new Date()){
                d.data.tempDeadline = parentDeadline;
            }
            
            
            if(d.children){
                d.children.forEach((child:any) => visit(child,(d.data.tempDeadline || d.data.deadline || null)))
            }
        }

        
        // visit(root);
        // function visit(d:any){

        //     if(d.depth === 3){
        //         const earliestDeadlineNode = findEarlyestDeadline(d);
        //         // console.log(earliestDeadlineNode);
        //         inheritChildrenDeadline(earliestDeadlineNode || null);
        //     }

        //     if(d.children){
        //         d.children.forEach((child:any) => visit(child))
        //     }
        // }

        // function findEarlyestDeadline(root:any){
    
        //     const rootDeadline = root.data.deadline;
        //     let earliestDeadline = new Date(root.data.deadline).getTime();
        //     let earliestDeadlineNode = undefined;
    
        //     visit(root);
        //     function visit(d:any){

        //         // find earliest deadline 
        //         if(!d.data.isCompleted && d.depth > 3 && d.data.deadline && earliestDeadline > new Date(d.data.deadline).getTime()){
        //             earliestDeadline = new Date(d.data.deadline).getTime();
        //             earliestDeadlineNode = d;
        //         }
    
        //         // inherit deadline 
        //         if(!d.data.isCompleted && d.depth > 3 && !d.data.deadline){
        //             if(d.parent.data.deadline){
        //                 d.data.tempDeadline = d.parent.data.deadline;
        //                 d.data.inheritedFrom = d.parent.data._id;
        //             }
        //             else {
        //                 d.data.tempDeadline = rootDeadline;
        //                 d.data.inheritedFrom = root.data._id;
        //                 // console.log(d.data.objective,d.data.inheritedFrom);
        //             }
        //         }

        //         if(d.children){
        //             d.children.forEach((child:any) => visit(child))
        //         }
        //     }
    
        //     // console.log(earliestDeadline);

        //     return earliestDeadlineNode;
        // }
    
        // function inheritChildrenDeadline(leaf:any){
        //     visitTreeFromLeafToRoot(leaf?.parent || null)
    
        //     function visitTreeFromLeafToRoot(node:any) {
        //         if (!node || node?.depth < 3 || node === null) {
        //           return;
        //         }
        //         // console.log(node.data.objective);
        //         if(!node.data.tempDeadline){
        //             node.data.tempDeadline = leaf.data.deadline;
        //             node.data.inheritedFrom = leaf.data._id;
        //         }
        //         // console.log(node.data.tempDeadline);
        //         visitTreeFromLeafToRoot(node.parent);
        //      }
        // }
    }
    
    function claculateNetRemaningMinutesTilDeadline(root:any){

        const state = store.getState();
        const events:any = state.events;

        function visit(d:any){
            // iteration from root to leaf 
            // if(d.depth > 0){
                // const totalMinutesTillDeadline = moment(d.data.tempDeadline || d.data.deadline).diff(moment(),"weeks");
                const daysTillDeadline = moment(d.data.tempDeadline || d.data.deadline).startOf("week").add(1,"day").diff(moment().endOf("week").add(1,"day"),"days");
                const weeks = daysTillDeadline/7;
                const currentEvents = events?.filter((ev:any) => ev.node._id === d.data.childOrGrandChildOf);
                const currentScheduledMinutes = currentEvents?.reduce((a:any,b:any)=> {
                    const startWeekMinutes = getMinutesInFirstWeek(b);
                    const endWeekMinutes = getMinutesInLastWeek(b,d);
                    return a + (startWeekMinutes+endWeekMinutes);
                },0);
                
                const totalScheduledMinutes = currentEvents?.reduce((a:any,b:any)=> a+moment(b.endTime).diff(moment(b.startTime),"minutes"),0)
                d.data.netRemainingMinutes = (totalScheduledMinutes*weeks)+currentScheduledMinutes;
    
                d.data.netRemainingMinutes = d.data.netRemainingMinutes <= 0? 1:d.data.netRemainingMinutes;
           
            if(d.children){
                // call visit func for every children 
                d.children.forEach((td:any) => visit(td));
            }
        }
        visit(root);
    
        function getMinutesInFirstWeek(event:any){
                const now = moment();
                const startTime = moment(event.startTime);
                const endTime = moment(event.endTime);
                const eventTotalMinutes = endTime.diff(startTime,"minutes");
                const startOfEventWeek = startTime.clone().startOf('week').add(1,"day");
    
                const startOfThisWeek = now.clone().startOf('week').add(1,"day");
    
                const minutesWhereEventStart = startTime.diff(startOfEventWeek, 'minutes');
                const minutesInThisWeekUntilNow = now.clone().diff(startOfThisWeek, 'minutes');
                const minutesLeftInThisWeek = (minutesWhereEventStart + eventTotalMinutes) - minutesInThisWeekUntilNow;
                const totalTimeForThisWeek = minutesLeftInThisWeek < 0 ? 0:minutesLeftInThisWeek>eventTotalMinutes?eventTotalMinutes:minutesLeftInThisWeek;
    
    
                return totalTimeForThisWeek;
        }
    
        function getMinutesInLastWeek(event:any,d:any){
                const myDeadline = d.data.tempDeadline || d.data.deadline;
                const deadlineStartOfWeek = moment(myDeadline).startOf("week").add(1,"day");
                const deadline = moment(myDeadline);
    
                
      
                const startTime = moment(event.startTime);
                const endTime = moment(event.endTime);
                const eventTotalMinutes = endTime.diff(startTime,"minutes");
                const startOfEventWeek = startTime.clone().startOf('week').add(1,"day");
    
                const minutesWhereEventStart = startTime.diff(startOfEventWeek, 'minutes');
                const minutesInThisWeekUntilNow = deadline.diff(deadlineStartOfWeek, 'minutes');
                const minutesLeftInThisWeek = minutesInThisWeekUntilNow-minutesWhereEventStart;
                const totalTimeForThisWeek = minutesLeftInThisWeek < 0 ? 0:minutesLeftInThisWeek>eventTotalMinutes?eventTotalMinutes:minutesLeftInThisWeek;
    
                
    
                return totalTimeForThisWeek;
        }
    }

    // depends on (totalEstimatedTaskDuration,estimatedTaskDuration,totalTaskExploitationDuration)
    function calculateNoOfFocusBlocks(root:any) {

        const state = store.getState();
        const events:any = state.events;
        const todaysElepsedMinutes = moment().diff(moment().startOf("day"),'minutes');
        const todayEvent = events?.find((ev:any) => moment(ev.startTime).day() === moment().day()  && todaysElepsedMinutes >= moment(ev.startTime).diff(moment(ev.startTime).startOf("day"),"minutes") && todaysElepsedMinutes <= moment(ev.endTime).diff(moment(ev.endTime).startOf("day"),"minutes"));
        // console.log(todaysElepsedMinutes);
        let projects:any = [];

        function visit(d:any){
            // iteration from root to leaf 
            if(d.depth === 3){
                const duration = d.data.totalEstimatedTaskDuration || d.data.estimatedTaskDuration;
                d.data.estNoOfFocusBlocksToCompleteProjectOkr = duration/30;

                if(!d.data.isCompleted  && !d.data.isDefered && d.parent.data._id === todayEvent?.node._id){
                    projects.push({
                        _id: d.data._id,
                        parent: d.parent.data._id,
                        objective: d.data.objective,
                        grossRemainingMinsTilDeadline: d.data.grossRemainingMinsTilDeadline,
                        estNoOfFocusBlocksToCompleteProjectOkr: d.data.estNoOfFocusBlocksToCompleteProjectOkr,
                        relativeImpactValueNodeAsScale10to0: d.data.relativeImpactValueNodeAsScale10to0,
                        scheduledFocusBlocksPerProjectOkr: 0,
                        unsheduledFocusBlocksToCompleteProjectOkr: d.data.estNoOfFocusBlocksToCompleteProjectOkr,
                    });
                }
                
            }
            if(d.children){
                // call visit func for every children 
                d.children.forEach((td:any) => visit(td));
            }
        }
        visit(root);

        // console.log([...projects]);
    
        let scheduledFocusBlocks = [];

        while ( projects.length > 0 ) {
            let winner:any = undefined;
            const sumOfUnsheduledFocusBlocksToCompleteAllProjectOkr = projects.reduce((a:any,b:any)=> a+b.estNoOfFocusBlocksToCompleteProjectOkr,0);
            
            projects.forEach((project:any) => {
                
                project.estFocusBlocksForNodeAsRelativeScale10To1 = (project.estNoOfFocusBlocksToCompleteProjectOkr / sumOfUnsheduledFocusBlocksToCompleteAllProjectOkr) * 10;
                
                project.netRemaingFocusBlocksTilDeadline = (project.grossRemainingMinsTilDeadline - (project.estNoOfFocusBlocksToCompleteProjectOkr*30))/30;
                
                project.relativeProductivityScoreForProjectOKr = (project.estFocusBlocksForNodeAsRelativeScale10To1/project.relativeImpactValueNodeAsScale10to0)*(project.netRemaingFocusBlocksTilDeadline/sumOfUnsheduledFocusBlocksToCompleteAllProjectOkr);
                
                project.focusBlocksCompetitionValue = project.relativeProductivityScoreForProjectOKr/project.unsheduledFocusBlocksToCompleteProjectOkr;               
                
                if(!winner || winner.focusBlocksCompetitionValue > project.focusBlocksCompetitionValue){
                    winner = {...project};
                }
            })
            
            let block = 1;

            // increase scheduledFocusBlocks and decrease unscheduled focus blocks 
            projects = projects.map((pro:any) => {
                if(pro.objective !== winner.objective){
                    return pro;
                }

                if(pro.unsheduledFocusBlocksToCompleteProjectOkr < 1){
                    block = pro.unsheduledFocusBlocksToCompleteProjectOkr;
                    return {...pro,
                        scheduledFocusBlocksPerProjectOkr: pro.scheduledFocusBlocksPerProjectOkr+pro.unsheduledFocusBlocksToCompleteProjectOkr,
                        unsheduledFocusBlocksToCompleteProjectOkr: pro.estNoOfFocusBlocksToCompleteProjectOkr-(pro.scheduledFocusBlocksPerProjectOkr+pro.unsheduledFocusBlocksToCompleteProjectOkr),
                    }
                }

                return {...pro,
                    scheduledFocusBlocksPerProjectOkr: pro.scheduledFocusBlocksPerProjectOkr+1,
                    unsheduledFocusBlocksToCompleteProjectOkr: pro.estNoOfFocusBlocksToCompleteProjectOkr-(pro.scheduledFocusBlocksPerProjectOkr+1),
                };
            });

            // const isExist = scheduledFocusBlocks.find(fb => fb.objective === winner.objective);
            
            // if(isExist){
            //    scheduledFocusBlocks = scheduledFocusBlocks.map(fb => fb.objective === winner.objective? {...winner,focusBlocks: fb.focusBlocks+block}:{...winner});
            // }
            // else {
            scheduledFocusBlocks.push({...winner,block});
            // }

            // remove scheduled project 
            projects = projects.filter((pro:any) => pro.unsheduledFocusBlocksToCompleteProjectOkr !== 0);
        }

        // globalFBSlotTable = scheduledFocusBlocks;
        // store.dispatch(setFocusBlocks(scheduledFocusBlocks));
    }

    function numberOfFocusBlocksTillDeadline(root:any){

        function visit(d:any){
            // iteration from root to leaf 
            // if(d.depth > 0){
                d.data.numberOfFocusBlocksTillDeadline = d.data.netRemainingMinutes/30;
            // }
    
            if(d.children){
                // call visit func for every children 
                d.children.forEach((td:any) => visit(td));
            }
        }
        visit(root);
    
    }
    
    // RelativeEstFBvalue10to0
    function relativeEstDurationValueNodeAsScale10to0(root:any) {

        function visit(d:any){
            // iteration from root to leaf 
            if (d.data.children.length > 0) {
                    // find max est totalDuration and save it into negativeInfinity
                    // let biggestEstTaskDuration:any = null;

                    // d.data.children.forEach((n:any) => {
                    //     let duration = n.totalEstimatedTaskDuration||n.estimatedTaskDuration;
                    //     let taskExploitationDuration = n.taskExploitationDuration;
                    
                        
                    //     if (taskExploitationDuration > duration) {
                    //         duration = taskExploitationDuration
                    //     }
                    //     else {
                    //         duration = duration-taskExploitationDuration;
                    //     }
        
                    //     if (biggestEstTaskDuration === null || (duration > biggestEstTaskDuration)) {
                    //         biggestEstTaskDuration = duration;
                    //     }
                    // });

                    
                    d.data.children.forEach((n:any) => {
                        // const duration = n.totalEstimatedTaskDuration || n.estimatedTaskDuration;
                        // const test = (duration / biggestEstTaskDuration) === 0?1:(duration / biggestEstTaskDuration);
                        n.relativeEstDurationValueNodeAsScale10to0 = 10;
                        // console.log(n.relativeEstDurationValueNodeAsScale10to0);
                    });
            }

            if(d.children){
                // call visit func for every children 
                d.children.forEach((td:any) => visit(td));
            }
        }
        visit(root);

    }

    function nodeProductivityValue(root:any) {

        function visit(d:any){
            // iteration from root to leaf 
            const REDVNAS10 = d.data.relativeEstDurationValueNodeAsScale10to0;
            const RIVNAS10 = d.data.relativeImpactValueNodeAsScale10to0;
            const relativeProductivityValue =  REDVNAS10/RIVNAS10;
            const duration = d.data.totalEstimatedTaskDuration || d.data.estimatedTaskDuration;
            const temporalValue = (duration / d.data.netRemainingMinutes) === 0? 1:(duration / d.data.netRemainingMinutes);     
            d.data.nodeProductivityValue = relativeProductivityValue/temporalValue;
            
            // if(d.data._id === "66e7ebcda64bcd55168bf22e"){
            //     console.log(d.data.nodeProductivityValue);
            // }
    
            if(d.children){
                // call visit func for every children 
                d.children.forEach((td:any) => visit(td));
            }
            else {
                d.data.estimatedProductivityPoints = (1/d.data.nodeProductivityValue)*100;
            }
        }
        visit(root);
    }

    function productivityPoints(root:any) {


        function visit(d:any){    
            // d.data.nodeProductivityValue 
            // EPP: {parseFloat(`${(1/d.data.nodeProductivityValue)*100}`).toFixed(0)}
            
            if(d.children){
                // call visit func for every children 
                d.children.forEach((td:any) => visit(td));


                d.data.estimatedProductivityPoints = d.data.children.reduce((a:any,b:any)=> a+b.estimatedProductivityPoints,0);
                // console.log(d.data?.estimatedProductivityPoints);
            }
        }
        visit(root);
    }




    // function nodeProductivityWeightedValue(root:any) {

    //     function visit(d:any){
    
    //         if(d.data.children?.length === 0){
    //             const temp = d.data.taskExploitationDurationAsArray.reverse().find((n:any) => n.okrGradePercentage);
    //             const progress = temp?.okrGradePercentage || 0;
    //             d.data.completedPortion = d.data.isCompleted? 1:progress/100; 
    //         }
    
           
    //         if(d.children) {
    //             // call visit func for every children 
    //             d.children.forEach((td:any) => visit(td));
    //             // d.depth > 0 && 
    //             if (d.data.children.length > 0) {
    //                 // sum of all children NPV
    //                 const sumOfChildrenNPVs = d.data.children.reduce((a:any,b:any)=> a + b.nodeProductivityValue,0);
    
                    
    //                 const sumOfChildrenInverseNVPs = d.data.children.reduce((a:any,b:any)=> a + (sumOfChildrenNPVs/b.nodeProductivityValue),0);
                    
                    
    //                 d.data.children.forEach((child:any) => {
    //                         const NPV = child.nodeProductivityValue;
    //                         const inverse = sumOfChildrenNPVs/NPV;
    //                         child.nodeProductivityWeightedValue = inverse/sumOfChildrenInverseNVPs;
    //                 });
    //             }
               
    //         }
    //     }
    //     visit(root);
    
    // }

    // let inCompletedNode:any = undefined;
    // function nodeComplition(root:any) {

    //     function visit(d){
    
    //         if(d.data.isSomeOneWorkingOnThis){
    //             inCompletedNode = d.data._id;
    //         }
    
    //         if(d.children) {
    //             // call visit func for every children 
    //             d.children.forEach(td => visit(td));
    
    //             // sum of all  children NPWV
    //             const sumOfNPWV = d.data.children.reduce((a,b) => {
    //                 let sum = (b.completedPortion*b.nodeProductivityWeightedValue);
                    
    //                 return a + sum;
    //             },0);
                
    //             d.data.completedPortion = sumOfNPWV;
    //             // if(d.data._id === "64707e9f82a2c23ec8d9efd6"){
    //             //     console.log(d);
    //             // }
    //         }
    //     }
    //     visit(root);
    
    // }

    // function findCurrentTask(root:any,parentObjectiveNode:any){

    //     const state = store.getState();
    
    //     function visit(d){
    
    //         remove completed nodes 
    //         if(!state.globalStates.isCompletedNodesVisible){
    //             d.data.children = d.data.children?.filter(c => !c.isCompleted);
    //             const ch = d.children?.filter(c => !c.data.isCompleted);
    
    //             if(ch?.length > 0){
    //                 d.children = ch;
    //             }
    //             else {
    //                 d.children = undefined;
    //             }
    //         } 
            
    //         if(d.data._id === "65dedb6509c313fa155fd89b"){
    //             console.log(d);
    //         }
    
    //         if(inCompletedNode === d.data._id){
    //             parentObjectiveNode.push(d.data);
    //             let parent = d.parent;
    //             while (parent) {
    //                 parentObjectiveNode.push(parent.data);
    //                 if(parent.depth >= 3){
    //                     parent = parent.parent
    //                 }
    //                 else {
    //                     parent = null;
    //                 }
    //             }
    //             currentTask = d.data;
    //         }
    
    //         if(state.globalStates.manuallyScheduleNodeId === d.data._id){
    //             function findCurrentTask(n) {
    //                 if (n.children.length > 0) {
    
                        
    //                     let taskOrdered = [...n.children].filter(item => !item.isCompleted && !item.isDefered);
    
                     
    //                     taskOrdered.sort((a,b)=> a.nodeProductivityValue - b.nodeProductivityValue);
    //                     taskOrdered.sort((a,b)=> {
    //                         // if task order is either 0 or undefined send that nod eto the end 
    //                         const aTO = !a.taskOrder? 1000:a.taskOrder;
    //                         const bTO = !b.taskOrder? 1000:b.taskOrder;
    //                         return aTO  - bTO;
    //                     });
                        
    //                     currentTask = taskOrdered[0];
                        
    //                     if (currentTask?.children.length > 0 ) {
    //                         parentObjectiveNode.push(currentTask);
    //                         findCurrentTask(currentTask);
    //                     }
    
    //                 }
    //             }
    //             parentObjectiveNode.push(d.data);
    //             findCurrentTask(d.data);
    //         }
    
    //         iteration from root to leaf 
    //         if (d.depth === 3 && !inCompletedNode && !state.globalStates.manuallyScheduleNodeId) {
    //             if (d.data._id === globalFBSlotTable[0]?._id && !d.data.isCompleted) {
                    
    //                 // console.log(d.data);
    //                 if(d.data.children?.length === 0){
    //                     currentTask = d.data;
    //                 }
                    
    //                 function findCurrentTask(n) {
    //                     if (n.children.length > 0) {
    //                         let taskOrdered = [...n.children].filter(item => !item.isCompleted && !item.isDefered);
                         
    //                         taskOrdered.sort((a,b)=> a.nodeProductivityValue - b.nodeProductivityValue);
                            
    //                         taskOrdered.sort((a,b)=> {
    //                             // if task order is either 0 or undefined send that nod eto the end 
    //                             const aTO = !a.taskOrder? 1000:a.taskOrder;
    //                             const bTO = !b.taskOrder? 1000:b.taskOrder;
    //                             return aTO  - bTO;
    //                         });
                            
    //                         if(taskOrdered.length === 0){
    //                             return;
    //                         }
    //                         currentTask = taskOrdered[0];
    
    //                         if (currentTask?.children.length > 0) {
    //                             parentObjectiveNode.push(currentTask);
    //                             findCurrentTask(currentTask);
    //                         }
    //                     }
    //                 }
    //                 parentObjectiveNode.push(d.data);
    //                 findCurrentTask(d.data);
    //             }
    //         }
    
    //         if(d.children){
    //             // call visit func for every children 
    //             d.children.forEach(td => visit(td));
    //         }
    //     }
    //     visit(root);
    
       
    
      
    //    // console.log(currentTask);
    // //    if(currentTask && state.globalStates.currentTask && state.globalStates.currentTask?._id !== currentTask._id){
    // //        store.dispatch(setUpcomingTask(currentTask));
    // //        store.dispatch(setUpcomingTasksParents(parentObjectiveNode));
    // //    }
    // //    else {
    // //         store.dispatch(setCurrentTask(currentTask));
    // //         store.dispatch(setCurrentTasksParents(parentObjectiveNode));
    // //    }
    // }
}


function findUpcommingTask(events:any,root:any,currentTask:any){
    const todayEvent = getTCurrentEvent(events);
    const categoryOfChange:any = root.descendants().find((d:any) => d.data._id === todayEvent?.node._id);
    const undeferedTask:any = categoryOfChange?.data.children?.find((c:any) => !c.isCompleted && !c.isDefered);

    if (undeferedTask) {
        const result:any = getCurrentTask(undeferedTask, [categoryOfChange.data]);
        
        if(currentTask?._id !== result.currentTask?._id){
            store.dispatch(setUpcomingTask(result.currentTask));
            store.dispatch(setUpcomingTasksParents(result.parentObjectives));
        }
    }
}









