import { 
    LOGIN_USER, 
    LOGOUT_USER,
    RESET_AUTH, 
} from "../../constants";

interface AuthAction {
    type: string;
    payload?: any;
}

const auth = (state: any = null, action: AuthAction): any => {
    switch (action.type) {
        case RESET_AUTH:
              return null;

        case LOGIN_USER:
            return action.payload;

        case LOGOUT_USER:
            return null;
    
        default:
            return state;
    }
}

export default auth;