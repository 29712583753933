import { fetchRequest } from "../../utils";
import { 
    DOMAIN_NAME
} from "../../constants"
import {IPlanningDuration} from "../../types/planningDuration";


interface IRes {
    success: boolean;
    message: string;
}

interface IGetExploitationDurationsOfLoggedInUser {
    startDate: Date;
    endDate: Date;
}

export const getPlanningDurationsOfLoggedInUser = async ({startDate,endDate}:IGetExploitationDurationsOfLoggedInUser) =>  {

    interface Res extends IRes {
        planningDurations: IPlanningDuration[]
    }

    try {
        const res: Res = await fetchRequest(`${DOMAIN_NAME}/planning-durations/user?startDate=${startDate}&endDate=${endDate}`, "GET", {});
        return res;
        
    } catch (error) {
        console.error(error);
    }
}

interface ICreatePlanningDuration  {
    startTime: Date; 
    endTime: Date;
    node?: string; 
}

export const createPlanningDuration = async (planningDuration : ICreatePlanningDuration ) => {

    interface Res extends IRes {
        planningDuration: IPlanningDuration
    }

    try {

        const res: Res = await fetchRequest(`${DOMAIN_NAME}/planning-durations`, "POST", {...planningDuration,startTime: new Date(planningDuration.startTime)} );
        
        return res;
        
    } catch (error) {
        console.error(error);
    }
}